import React from 'react';

import ZumiBrandImg from '../../../assets/zumiBrandImg.png'

const PrivacyPolicy = () => {
  return (
    <div className='termsMain_cont'>
      <div className="termsTool_cont">
        <div className="toolbarMain_cont">
          <div className="toolbarBrandMain_cont centerElm">
            <div className="toolBrandImg_cont">
              {/* <img src={ZumiBrandImg} alt="brandImg" /> */}
              <img src={ZumiBrandImg} alt="brandImg" style={{ height: 40, width: 80 }} />
              {/* <h1 className='toolbarBrandName'>ZUMI</h1> */}
            </div>
          </div>
        </div>
      </div>
      <div className="termsInner_cont">
        <div className="termsSub_cont">
          <p className='termsMainHead'>Privacy Policy</p>
          <div className="terms_cont">
            <p>Effective Date: 20 March 2025</p>
            {/* <p>Welcome to Zumi.au. Your privacy is important to us. This Privacy Policy explains how we collect, use, store, and protect your personal information when you interact with our website, products, and services. By using our services, you consent to the practices described below..</p> */}
          </div>

          <div className="terms_cont">
            <p className='termsBoldPara'><strong>Information We Collect</strong></p>
            <p>We collect and process the following types of information in accordance with applicable laws and regulations:</p>
            <p className=''><strong>•	Personal Information : </strong>Includes name, email address, phone number, and other details provided voluntarily by users when registering, making inquiries, or completing transactions.</p>
            <p className=''><strong>• Payment Information:  </strong>Processed securely through third-party payment providers. We do not store or retain payment details.</p>
            <p className=''><strong>• Usage Data : </strong>Includes IP address, device details, browser type, pages visited, interactions, and timestamps collected to improve functionality, ensure security, and enhance user experience.</p>
            <p className=''><strong>• Cookies and Tracking Technologies: </strong>We use cookies, web beacons, and analytics tools to enhance user experience and website functionality. You can manage cookie preferences through your browser settings.</p>


            <p className='termsBoldPara'><strong>How We Use Your Information</strong></p>
            <p>We use the collected information to:</p>
            <p>•  Provide, operate, and improve our services</p>
            <p>•	Process transactions and send order confirmations.</p>
            <p>•	Respond to customer inquiries and provide support.</p>
            <p>•	Send promotional and marketing communications, only if users have provided explicit consent, which can be withdrawn at any time.</p>
            <p>•	Prevent fraud, security risks, and potential abuse.</p>
            <p>•	Comply with legal and regulatory obligations.</p>
            <p>Users can update their communication preferences through their account settings or by contacting us at support@zumi.au.</p>


            <p className='termsBoldPara'><strong>How We Share Your Information</strong></p>
            <p>We do not sell, rent, or trade users' personal data with third parties for their marketing purposes. However, we may share information in the following circumstances:</p>
            <p className=''><strong>• Service Providers: </strong>Third-party vendors that assist with payment processing, marketing, analytics, hosting, and technical support.</p>
            <p className=''><strong>• Legal & Compliance Obligations: </strong>When required by law, legal proceedings, or regulatory authorities.</p>
            <p className=''><strong>• Business Transfers: </strong>In the event of a merger, acquisition, or asset sale, personal data may be transferred as part of the business transaction, and users will be notified if required by law.</p>


            <p className='termsBoldPara'><strong>Data Security</strong></p>
            <p>We implement industry-standard security measures, including encryption, secure access controls, and periodic security audits to protect personal data from unauthorised access, disclosure, alteration, or destruction. While we take reasonable security precautions, no method of transmission over the internet or electronic storage is 100% secure.</p>


            <p className='termsBoldPara'><strong>Your Rights & Choices</strong></p>
            <p>Depending on applicable data protection laws, you may have the right to:</p>
            <p className=''><strong>•	Access & Update Data:  </strong>Request access to or correction of your personal information.</p>
            <p className=''><strong>•	Opt-out of Marketing: </strong>Unsubscribe from promotional emails at any time.</p>
            <p className=''><strong>•	Request Data Deletion: </strong>Users may request the deletion of personal data, subject to legal, contractual, or security obligations requiring its retention.</p>
            <p className=''><strong>•	Restrict Data Processing: </strong>Limit certain data processing activities under specific circumstances.</p>
            <p>To exercise your rights, contact us at support@zumi.au. We will respond within the legally required timeframe.</p>


            <p className='termsBoldPara'><strong>Cookies & Tracking Technologies</strong></p>
            <p>We use cookies and similar tracking tools to enhance user experience, personalise content, and analyse website performance. Users can manage cookie preferences through browser settings or our cookie banner. You can manage your cookie preferences via your browser settings or through our cookie banner.</p>

            <p className='termsBoldPara'><strong>Third-Party Links & Integrations</strong></p>
            <p>Our website may contain links to third-party websites or services. We do not control their privacy policies and encourage users to review their policies before providing personal data. We are not responsible for their privacy practices and recommend reviewing their policies before sharing personal data.</p>

            <p className='termsBoldPara'><strong>International Data Transfers</strong></p>
            <p>If we transfer personal data outside the user’s jurisdiction, we use appropriate safeguards, including contractual clauses, legal frameworks, or other mechanisms, to ensure compliance with applicable data protection laws</p>

            <p className='termsBoldPara'><strong>Policy Updates</strong></p>
            <p>We may update this Privacy Policy from time to time. If significant changes are made, we will notify users via our website or email, as required by law. Continued use of our services constitutes acceptance of the updated policy.</p>

            <p className='termsBoldPara'><strong>Contact Us</strong></p>
            <p>For any privacy-related questions, concerns, or data requests, contact us at:</p>
            <p><strong>ZUMI</strong></p>
            <p>support@zumi.au</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy;
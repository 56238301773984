import React from 'react';
import './WelcomeFooter.css';
import zumiBrandImg from '../../../assets/zumiInverted.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';


const WelcomeFooter = () => {

  const { t } = useTranslation();

  return (
    <div className='welFooterMain_cont'>
      <div className="welFooterSub_cont1">
        <div className="welFooterLinks_cont" style={{ marginRight: "20px", textAlign: "center" }}>
          <div className="footerBrandCont">
            <img src={zumiBrandImg} alt="" />
          </div>
          <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>1300 007 007</h2>
          <p>info@zumi.com.au</p>
          <div className="welFootSocialLinks_cont">
            <div className="footSocialLink_cont centerElm">
              <a href=""><FacebookIcon style={{ color: "darkgray" }} /></a>
            </div>
            <div className="footSocialLink_cont centerElm">
              <a href=""><XIcon style={{ color: "darkgray" }} /></a>
            </div>
            <div className="footSocialLink_cont centerElm">
              <a href=""><LinkedInIcon style={{ color: "darkgray" }} /></a>
            </div>
            <div className="footSocialLink_cont centerElm">
              <a href=""><InstagramIcon style={{ color: "darkgray" }} /></a>
            </div>
            <div className="footSocialLink_cont centerElm">
              <a href=""><YouTubeIcon style={{ color: "darkgray" }} /></a>
            </div>
          </div>
        </div>
        <div className="welFooterLinks_cont">
          <p className='welFootCardHead'>{t('welFootCardHead1')}</p>
          <p>{t('welFootCardPara1')}</p>
          <p>{t('welFootCardPara2')}</p>
          <p>{t('welFootCardPara3')}</p>
          <p>{t('welFootCardPara4')}</p>
        </div>
        <div className="welFooterLinks_cont">
          <p className='welFootCardHead'>{t('welFootCardHead2')}</p>
          <p>{t('welFootCardPara5')}</p>
          <p>{t('welFootCardPara6')}</p>
          <p>{t('welFootCardPara7')}</p>
        </div>
        <div className="welFooterLinks_cont">
          <p className='welFootCardHead'>{t('welFootCardHead3')}</p>
          <p>{t('welFootCardPara8')}</p>
          <p>{t('welFootCardPara9')}</p>
          <p>{t('welFootCardPara10')}</p>
          <p></p>
        </div>
        <div className="welFooterLinks_cont">
          <p className='welFootCardHead'>{t('welFootCardHead4')}</p>
          <p>{t('welFootCardPara11')}</p>
          <p>{t('welFootCardPara12')}</p>
          <p></p>
        </div>
      </div>

      <hr />

      <div className="welFooterSub_cont2">
        <div className="welFootSub">
          © Copyright ZUMI | All rights reserved
        </div>
        <div className="welFootSub">
          <Link className='welFootLink' to="/legal/privacy-policy">Privacy policy</Link> | <Link className='welFootLink' to="/terms&conditions">Terms of service</Link>
        </div>


      </div>
    </div>
  )
}

export default WelcomeFooter;
import React, { useState, useEffect, useContext, useMemo } from 'react';
import './Catalogs.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';
import TextField from '@mui/material/TextField';
import { utils, writeFile } from 'xlsx';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import DownloadIcon from '@mui/icons-material/Download';
import { Link } from 'react-router-dom';

import { SelectedMarketplaceContext } from '../../../Context/SelectedMarketplaceContext';
import CatalogView from './CatalogView/CatalogView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  // height: 300,
  height: "auto",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const createSmsStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 950,
  height: 560,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const Catalogs = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const catalogs_URL = `${baseUrl}/zumi/api/existingcatalog`;
  const manualCatalogs_URL = `${baseUrl}/zumi/api/catalog/manual`;
  const activity_URL = `${baseUrl}/zumi/api/catalog/useractivity`;
  const { selectedMarketplace } = useContext(SelectedMarketplaceContext);

  const [catalogsLoader, setCatalogsLoader] = useState(true);
  const [catalogsData, setCatalogsData] = useState([]);
  const [manualCatalogData, setManualCatalogData] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const [searchSkuQuery, setSearchSkuQuery] = useState('');
  const [searchManualSkuQuery, setSearchManualSkuQuery] = useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState('');
  const [fileToView, setFileToView] = useState("");

  const [catalogViewOpen, setCatalogViewOpen] = useState(false);
  const handleCatalogViewOpen = (id) => setCatalogViewOpen(true);
  const handleCatalogViewClose = () => setCatalogViewOpen(false);

  const [downloadLoader, setDownloadLoader] = useState(false);

  const [activeTab, setActiveTab] = useState('marketCatalog');

  const [singleFileData, setSingleFileData] = useState(null);

  const handleTabClick = (tab) => {
    setActiveTab(tab); // Set the active tab
  };

  const openCatalogView = (fileName) => {
    setFileToView(fileName);
    handleCatalogViewOpen();
  }

  const columns = [
    {
      name: t("marketplace"),
      selector: row => row.title,
      width: "120px",
    },
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "160px",
    },
    {
      name: t("asin"),
      selector: row => {
        if (row.asin) {
          return row.asin
        }
        else {
          return row.sku
        }
      },
      width: "160px",
    },
    {
      name: t("productInfo"),
      selector: row => row.itemName,
      width: "320px",
    },
    {
      name: t("quantity"),
      selector: row => row.quantity,
      width: "120px",
      sortable: true,
    },
    {
      name: t("price"),
      selector: row => row.amount,
      width: "90px",
      sortable: true,
    },


  ];
  const manualColumns = [
    {
      name: t("sku"),
      selector: row => row.sku,
      width: "160px",
    },
    {
      name: t("specs"),
      selector: row => row.specs,
      width: "160px",
    },
    {
      name: t("description"),
      selector: row => row.product,
      width: "380px",
    },
    {
      name: t("quantity"),
      selector: row => row.quantity,
      width: "120px",
      sortable: true,
    },
    {
      name: t("price"),
      selector: row => row.price,
      width: "90px",
      sortable: true,
    },
    {
      name: t("createdDate"),
      // selector: row => row.created_date,
      selector: row => {
        const date = new Date(row.createdDate);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour12: true
        });
      },
    },


  ];
  const activityColumns = [
    {
      name: t("user"),
      selector: row => row.userName,
      width: "160px",
    },
    {
      name: t("fileName"),

      selector: row => row.file,
      width: "250px",
    },
    {
      name: t("date"),

      selector: row => row.createdDate,
      width: "160px",
    },
    {
      name: "",
      selector: row => <button className='btnFill taskDetailsView' onClick={() => { openCatalogView(row.file) }}>View Catalog</button>,
      width: "180px",
    },
    {
      name: "",
      selector: row => <button className='btnFill taskDetailsView' onClick={() => { getSingleCatalog(row.file) }}>Download</button>,
      width: "180px",
    },
  ];
  const sampleCatalogFile = [
    {
      SKU: "A102011117",
      ASIN: "B0BVRL5FVK",
      Product: "Zagg Aurora Milan Phone Case for Samsung ",
      Quantity: "12",
      Price: "46",
      Currency: "AUD",
    },
    {
      SKU: "A102011787",
      ASIN: "B0BVRL787KK",
      Product: "Zagg Element Milan Phone Case for Samsung ",
      Quantity: "18",
      Price: "40",
      Currency: "AUD",
    },
  ]

  // H A N D L I N G   F I L E   S E L E C T I O N
  const fileSelectedHandler = (event) => {
    const file = event.target.files[0]
    // setSelectedFile(file);

    if (file) {
      const fileName = file.name;
      const fileExtension = fileName.split('.').pop().toLowerCase(); // Get file extension

      // Check if the file extension is valid
      if (['csv', 'xlsx', 'xls'].includes(fileExtension)) {
        setFileError(''); // Clear error message
        setSelectedFile(file);
        // Proceed with processing the file
      } else {
        setFileError(t("invalidFileError"));
        event.target.value = ''; // Clear the input
      }
    }
  };

  // Handle file drop
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const file = event.dataTransfer.files[0];
    setSelectedFile(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  // U P L O A D   C A T A L O G
  const uploadCatalogs = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', selectedFile);

    for (let entry of formData.entries()) {
      console.log(entry[0] + ': ' + entry[1]);
    }

    fetch(`${baseUrl}/zumi/api/catalog/manual/upload`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${authToken}`, // Add the Authorization header
      },
    }).then(response => {
      if (response.ok) { // Check if the response status is in the range 200-299
        console.log(response);
        alert(t("fileUploadAlert"))
      } else {
        // Handle different status codes
        alert(t("fileNotUploadAlert"));
        // throw new Error(message); // Optionally throw an error to stop further processing
      }
    })
    setOpen(false);
    setSelectedFile(null);
  }

  // D O W N L O A D   C A T A L O G
  const handleDownloadCatalogs = () => {
    const selectedColumns = [
      { original: 'title', renamed: 'Marketplace' },
      { original: 'sku', renamed: 'SKU' },
      { original: 'asin', renamed: 'ASIN' },
      { original: 'itemName', renamed: 'Product Name' },
      { original: 'quantity', renamed: 'Quantity' },
      { original: 'amount', renamed: 'Price' },
    ];

    // Map over filtered data and create a new object with the renamed properties
    const filteredDataForDownload = filteredCatalogsData.map(item => {
      let selectedItem = {};
      selectedColumns.forEach(col => {
        selectedItem[col.renamed] = item[col.original];
      });
      return selectedItem;
    });

    // Convert the filtered data to a worksheet
    const ws = utils.json_to_sheet(filteredDataForDownload);
    const wb = utils.book_new(); // Create a new workbook
    utils.book_append_sheet(wb, ws, 'Catalog'); // Append the sheet to the workbook
    writeFile(wb, 'Catalog.xlsx'); // Save as an Excel file
  };

  // D O W N L O A D   M A N U A L    C A T A L O G
  const handleDownloadManualCatalogs = () => {
    const filteredDataForDownload = filteredManualCatalogsData.map(item => {
      return { ...item }; // Keep the item as is
    });

    const ws = utils.json_to_sheet(filteredDataForDownload);
    const wb = utils.book_new(); // Create a new workbook
    utils.book_append_sheet(wb, ws, 'ManualCatalog'); // Append the sheet to the workbook
    writeFile(wb, 'ManualCatalog.xlsx'); // Save as an Excel file
  };

  // D O W N L O A D   S A M P L E    C A T A L O G
  const handleSampleCatalogDownload = () => {
    const ws = utils.json_to_sheet(sampleCatalogFile);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'SampleCatalogrFileZumi');
    writeFile(wb, 'SampleCatalogFileZumi.csv', { bookType: 'csv' });
  };

  // D O W N L O A D   S I N G L E    C A T A L O G
  const handleDownloadSingleCatalogs = (fileName) => {
    if (singleFileData) {
      const selectedColumns = [
        { original: 'sku', renamed: 'SKU' },
        { original: 'specs', renamed: 'Specs' },
        { original: 'product', renamed: 'Description' },
        { original: 'quantity', renamed: 'Quantity' },
        { original: 'price', renamed: 'Price' },
        { original: 'createdDate', renamed: 'Created Date' },
      ];

      const filteredDataForDownload = singleFileData.map(item => {
        let selectedItem = {};
        selectedColumns.forEach(col => {
          selectedItem[col.renamed] = item[col.original];
        });
        return selectedItem;
      });

      // Convert the filtered data to a worksheet
      const ws = utils.json_to_sheet(filteredDataForDownload);
      const wb = utils.book_new(); // Create a new workbook
      utils.book_append_sheet(wb, ws, fileName); // Append the sheet to the workbook
      writeFile(wb, `${fileName}.xlsx`); // Save as an Excel file
    }

  };

  // G E T    C A T A L O G S,   M A N U A L   A N D    A C T I V I T Y    L O G 
  const handleTabDataFetch = async (url) => {
    if (authToken) {
      setCatalogsLoader(true);
      try {
        let fetchUrl = url;

        // Set URL based on active tab
        if (activeTab === 'marketCatalog') {
          fetchUrl = catalogs_URL;
        } else if (activeTab === 'manualCatalog') {
          fetchUrl = manualCatalogs_URL;
        } else if (activeTab === 'activityLog') {
          fetchUrl = activity_URL; // Fetch URL for activity log
        }

        const response = await fetch(fetchUrl, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) throw new Error('Network response was not ok');
        const result = await response.json();
        return result;
      } catch (error) {
        console.log(error);
        return [];
      } finally {
        setCatalogsLoader(false);
      }
    }
    return [];
  };

  // G E T   S I N G L E    C A T A L O G 
  const getSingleCatalog = (fileName) => {
    setDownloadLoader(true);
    if (authToken && fileName) {
      const fetchFileData = async () => {
        try {
          const response = await fetch(`${baseUrl}/zumi/api/catalog/manualbyfilename?filename=${fileName}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json'
            }
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const result = await response.json();
          console.log('Fetched data:', result);
          setSingleFileData(result);
          handleDownloadSingleCatalogs(fileName);
        } catch (error) {
          console.log('Error fetching file data:', error);
        } finally {
          setDownloadLoader(false); // Reset loader once done
        }
      };
      fetchFileData();
    }
  };

  // C A L L    F E T C H    B A S E D   O N    A C T I V E   T A B 
  useEffect(() => {
    const fetchData = async () => {
      const url = activeTab === 'marketCatalog' ? catalogs_URL : activeTab === 'manualCatalog' ? manualCatalogs_URL : activity_URL;
      const data = await handleTabDataFetch(url);
      if (activeTab === 'marketCatalog') {
        setCatalogsData(data);
      } else if (activeTab === 'manualCatalog') {
        setManualCatalogData(data);
      } else if (activeTab === 'activityLog') {
        setActivityData(data);
      }
    };
    fetchData();
  }, [selectedMarketplace, activeTab]);


  const handleSearchCatalogs = (e) => {
    setSearchSkuQuery(e.target.value);
  };

  const handleSearchManualCatalogs = (e) => {
    setSearchManualSkuQuery(e.target.value);
  };

  const filteredCatalogsData = useMemo(() => {
    return catalogsData.filter(item => item.sku.toLowerCase().includes(searchSkuQuery.toLowerCase()));
  }, [catalogsData, searchSkuQuery]);

  const filteredManualCatalogsData = useMemo(() => {
    return manualCatalogData.filter(item => item.sku.toLowerCase().includes(searchManualSkuQuery.toLowerCase()));
  }, [manualCatalogData, searchManualSkuQuery]);

  return (
    <div className='revenue_main'>
      <Breadcrumbs />
      <div className="locationMain_cont">
        <LocationSelect />
        <div className="buyBoxSearch_cont" >
          {activeTab === "marketCatalog" && (
            <TextField
              size="small"
              type='text'
              style={{ width: "100%" }}
              inputProps={{ autoComplete: 'off' }}
              id="outlined-basic"
              label="Search SKU"
              variant="outlined"
              name="max_price"
              value={searchSkuQuery}
              onChange={handleSearchCatalogs}
            />
          )}
          {activeTab === "manualCatalog" && (
            <TextField
              size="small"
              type='text'
              style={{ width: "100%" }}
              inputProps={{ autoComplete: 'off' }}
              id="outlined-basic"
              label="Search manual SKU"
              variant="outlined"
              name="max_price"
              value={searchManualSkuQuery}
              onChange={handleSearchManualCatalogs}
            />
          )}
        </div>
      </div>

      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          <div className="downloadCatalogs_cont">
            <div className="catalogNavBarMain">
              <div
                className={`catItem ${activeTab === 'marketCatalog' ? 'catalogsActiveTab' : ''}`}
                onClick={() => handleTabClick('marketCatalog')}
              >Synced Catalog</div>
              <div
                className={`catItem ${activeTab === 'manualCatalog' ? 'catalogsActiveTab' : ''}`}
                onClick={() => handleTabClick('manualCatalog')}
              >Manual Catalog</div>
              <div
                className={`catItem ${activeTab === 'activityLog' ? 'catalogsActiveTab' : ''}`}
                onClick={() => handleTabClick('activityLog')}
              >Activity Log</div>
            </div>

            <div className="catBtnsCont">
              {/* <button className='downloadCatalogsBtn' onClick={handleOpen}>Upload Catalog</button> */}
              {activeTab === "marketCatalog" && <button className='downloadCatalogsBtn' onClick={handleDownloadCatalogs}>Download Catalog</button>}
              {activeTab === "manualCatalog" && <button className='downloadCatalogsBtn' onClick={handleOpen}>Upload Manual</button>}
              {activeTab === "manualCatalog" && <button className='downloadCatalogsBtn' onClick={handleDownloadManualCatalogs}>Download Manual</button>}
            </div>

          </div>

          {/* {catalogsLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={activeTab === 'marketCatalog' ? columns : manualColumns}
              data={activeTab === 'marketCatalog' ? filteredCatalogsData : filteredManualCatalogsData}
              customStyles={tableStyles}

              pagination
            />
          )} */}

          {catalogsLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={
                activeTab === 'marketCatalog'
                  ? columns
                  : activeTab === 'activityLog'
                    ? activityColumns
                    : manualColumns
              }
              data={
                activeTab === 'marketCatalog'
                  ? filteredCatalogsData
                  : activeTab === 'activityLog'
                    ? activityData
                    : filteredManualCatalogsData
              }
              customStyles={tableStyles}
              pagination
            />
          )}

        </div>
      </div>



      {/* M O D A L   F O R   C A T A L O G    V I E W  */}
      <Modal
        className='templateStyleMobile'
        open={catalogViewOpen}
        onClose={handleCatalogViewClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={createSmsStyle}>
          <CatalogView btnText="Submit" fileToView={fileToView} closeModal={handleCatalogViewClose}></CatalogView>
        </Box>
      </Modal>


      {/* M O D A L   T O   U P L O A D   C A T A L O G S */}
      <Modal
        className='templateStyleMobile'
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="uploadCatalogsMain">
            <div className="newSegmentHeader">
              <div className="templateMessage_cont">
                <span className='templateMessage'>
                  {t('uploadCatalog')}
                </span>

              </div>
              <div className="closeBtn_cont" role="button" aria-label="Close" onClick={handleClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                </svg>
              </div>
            </div>

            <div className="uploadCatalogsSub">
              <p>Please upload your catalog file here to update your offerings.</p>
              <div className="dragDropCatalogs_cont" onDrop={handleDrop} onDragOver={handleDragOver}>
                <p style={{ fontSize: "13px" }}>{t('dragDropParaHalf1')}
                  <input type='file' accept=".csv, .xlsx, .xls" className='uploadContactFileBtn' name="" id="uploadBtn" onChange={fileSelectedHandler} />
                  <label htmlFor="uploadBtn" className='uploadContactFileLabel'>{t('chooseFile')}</label>
                  to upload your catalog. Supported file types are csv, xlsx and xls.</p>
                {selectedFile ? (
                  <p style={{ marginTop: "10px", fontWeight: "500" }}>{t('selectedFile')}<span className='fileName'>{selectedFile.name}</span> </p>
                ) : <p style={{ marginTop: "10px", fontWeight: "500" }} className='nofile'>{t('noFileSelected')}</p>
                }
                {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
              </div>
              <div className="uploadCatalogsBtn_cont">
                <div className="createBroadcastHelpLinks_cont">
                  <div className="helpAnchorCont">
                    <Link className='helpAnchor' onClick={handleSampleCatalogDownload}>
                      Sample Catalog
                      <DownloadIcon className='launchIcon' style={{ width: "18px", height: "18px", marginLeft: "5px" }}
                      />
                    </Link>
                  </div>
                </div>
                <button className='btnFill'
                  onClick={uploadCatalogs}
                  disabled={!selectedFile || fileError}
                  style={{
                    border: !selectedFile ? "1px solid #EEEEEE" : "#17c1e8",
                    backgroundColor: !selectedFile ? '#EEEEEE' : '#17c1e8',
                    color: !selectedFile ? 'gray' : '#fff',
                  }}
                >{t('upload')}</button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

    </div>
  )
}

export default Catalogs;

import React, { useState, useContext } from 'react';
import './EditProfile.css';
import { useNavigate, Link } from 'react-router-dom';
import { TextField } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { UserDetailsContext } from '../../../Context/UserDetailsContext';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "10px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
  },
}


const EditProfile = ({ showAlert, updateProfileData, closeModal }) => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const editProfile_URL = `${baseUrl}/zumi/api/users/updateprofile`;
  const { userDetails } = useContext(UserDetailsContext);

  const [updateUser, setUpdateUser] = useState(false);

  const [editProfileDetails, setEditProfileDetails] = useState(
    {
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      contactNumber: userDetails.contactNumber,
      emailId: userDetails.emailId,
      companyName: userDetails.companyName
    }
  )

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setEditProfileDetails({ ...editProfileDetails, [name]: value });
  }

  // H A N D L E   N E W   U S E R
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(editProfile_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editProfileDetails),
      });

      if (!response.ok) {
        const errorData = await response.json();
        closeModal();
        showAlert("Profile not updated.", '#FFE4C0', '#FD5D5D');
      }
      else {
        const data = await response.json();
        setUpdateUser(!updateUser)
        updateProfileData(updateUser);
        closeModal();
        showAlert("Profile updated successfully.", '#d4f4e6', '#77d98b');
      }

    } catch (err) {
      console.error('Error posting data:', err);
      closeModal();
      showAlert("Profile not updated due to an error.", '#FFE4C0', '#FD5D5D');
    } finally {
      closeModal();
    }
  };

  return (
    <div className='editProfileMain'>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {t('editProfile')}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="editProfileForm_cont">
        <div className="signupRow1">
          <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">First Name  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='text'
              name="firstName"
              id="outlined-basic"
              placeholder="Enter first name"
              variant="outlined"
              sx={textFieldStyle}
              value={editProfileDetails.firstName}
              onChange={handleChange}
            />
          </div>

          <div className="signupFormInput_cont">
            <label htmlFor="companyName" className="welcomeFormLabel">Last Name  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='text'
              name="lastName"
              id="outlined-basic"
              placeholder="Enter last name"
              variant="outlined"
              sx={textFieldStyle}
              value={editProfileDetails.lastName}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="signupRow2">
          <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
            <label htmlFor="emailId" className="welcomeFormLabel">Email  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='email'
              name="emailId"
              id="outlined-basic"
              placeholder="Enter your email"
              variant="outlined"
              sx={textFieldStyle}
              value={editProfileDetails.emailId}
              onChange={handleChange}
            />
          </div>

          <div className="signupFormInput_cont">
            <label htmlFor="contactNumber" className="welcomeFormLabel">Mobile  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='number'
              name="contactNumber"
              id="outlined-basic"
              placeholder="Enter mobile number"
              variant="outlined"
              sx={textFieldStyle}
              value={editProfileDetails.contactNumber}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="signupRow3">
          <div className="signupFormInput_cont" style={{ marginRight: "10px" }}>
            <label htmlFor="emailId" className="welcomeFormLabel">Company Name  <span className="asterisk">*</span></label>
            <TextField
              className='welcomeFormInput'
              InputLabelProps={{ style: { display: 'none' } }}
              inputProps={{ autoComplete: 'off' }}
              type='text'
              name="companyName"
              id="outlined-basic"
              placeholder="Enter company name"
              variant="outlined"
              sx={textFieldStyle}
              value={editProfileDetails.companyName}
              onChange={handleChange}
            />
          </div>

          <div className="signupFormInput_cont">
            {/* Empty container */}
          </div>
        </div>

        <div className="formInput_cont signupBtn_cont">
          <button className='btnFill loginBtn' onClick={handleSubmit}>Save Changes</button>
        </div>
      </div>

    </div>
  )
}

export default EditProfile;
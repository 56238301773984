import React, { useEffect, useState } from 'react';
import './CatalogView.css';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import ContactTableLoader from '../../../Loaders/ContactTableLoader/ContactTableLoader';

const CatalogView = ({ fileToView, closeModal, taskData }) => {

  const { t } = useTranslation();
  const authToken = localStorage.getItem('authToken');
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const [taskDetails, setTaskDetails] = useState(taskData);

  const [viewTaskLoader, setViewTaskLoader] = useState(true);

  const columns = [
    {
      name: t("sku"),
      selector: row => row.sku,
    },
    {
      name: t("specs"),
      selector: row => row.specs,
    },
    {
      name: t("description"),
      selector: row => row.product,
    },
    {
      name: t("quantity"),
      selector: row => row.quantity,
    },
    {
      name: t("price"),
      selector: row => row.price,
    },
    {
      name: t("createdDate"),
      selector: row => {
        const date = new Date(row.createdDate);
        return date.toLocaleString('en-US', {
          // weekday: 'long',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour12: true
        });
      },
    },

  ];

  const sentMessagesStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        width: "100%",
        // border: "1px solid green",
        fontSize: "1rem",
        lineHeight: "27px",
      },
    },
    headCells: {
      style: {
        padding: "10px",
        textAlign: "left",
        width: "100%",
      },
    },
    cells: {
      style: {
        padding: "10px",
        fontSize: "0.9rem",
        fontFamily: "Poppins, Montserrat",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  // F E T C H   T A S K  D A T A 
  useEffect(() => {
    if (authToken && fileToView) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${baseUrl}/zumi/api/catalog/manualbyfilename?filename=${fileToView}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            setViewTaskLoader(false)
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setTaskDetails(result);
          setViewTaskLoader(false);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, []);

  return (
    <div className='taskViewMain_cont'>
      <div className="newSegmentHeader">
        <div className="templateMessage_cont">
          <span className='templateMessage'>
            {/* Catalog - {taskDetails[0].file ? taskDetails[0].file : ""} */}
            Catalog - {taskDetails?.[0]?.file ? taskDetails[0].file : "--"}
          </span>

        </div>
        <div className="closeBtn_cont" role="button" aria-label="Close" onClick={closeModal}>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-x crossSvg" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>
      </div>

      <div className="viewTaskMain">

        <div className="viewTaskSearch_cont">

        </div>
        <div className="viewTaskTable_cont">
          <div className="viewTask_cont">

            {viewTaskLoader ? (
              <ContactTableLoader />
            ) : (
              <DataTable
                columns={columns}
                customStyles={sentMessagesStyles}
                data={taskDetails}
                pagination
              >
              </DataTable>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CatalogView;
import React from 'react';
import "./WelcomeMainContent.css";
import WhatsappImg1 from '../../../assets/welWhatsapp1.png';
import WhatsappImg2 from '../../../assets/welWhatsapp2.png';
import WhatsappImg3 from '../../../assets/welWhatsapp3.png';
import WhatsappImg4 from '../../../assets/welWhatsapp4.png';
import WhatsappImg5 from '../../../assets/welWhatsapp5.png';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const WelcomeMainContent = () => {
  const { t } = useTranslation();

  return (
    <div className='welcomeMainContentMain_cont'>
      {/* S E C T I O N 1 */}
      <div className="welcomeSection1" id="welcomeSection1">
        <div className="welcomeSection1Sub">
          <div className="welSecSubCont">
            <h1>{t("welSec1MainHead")}</h1>
          </div>
          <div className="welSecSubCont">
            <p>{t("welSection1Para")}</p>
            <Link className="welcomeButton" to="/signup">Get Started</Link>
          </div>
        </div>
      </div>

      {/* S E C T I O N 2 */}
      <div className="welcomeSection2">
        <div className="enquireFormMain_cont">
          <h3>ENQUIRE NOW</h3>
          {/* Form Row 1 */}
          <div className="enquireFormRow">
            <div className="enquireFormInput_cont" style={{ marginRight: "50px" }}>
              <label htmlFor="name" className="enquireFormLabel">{t('name')}  <span className="asterisk">*</span></label>
              <input
                type="text"
                className='enquireFormInput'
              />
            </div>

            <div className="enquireFormInput_cont">
              <label htmlFor="email" className="enquireFormLabel">{t('email')}  <span className="asterisk">*</span></label>
              <input
                type="email"
                className='enquireFormInput'
              />
            </div>
          </div>
          {/* Form Row 2 */}
          <div className="enquireFormRow">
            <div className="enquireFormInput_cont" style={{ marginRight: "50px" }}>
              <label htmlFor="name" className="enquireFormLabel">{t('subject')}  <span className="asterisk">*</span></label>
              <textarea
                type="text"
                className='enquireFormTextarea'
                rows={3}
              />
            </div>

            <div className="enquireFormInput_cont">
              <label htmlFor="email" className="enquireFormLabel">{t('message')}  <span className="asterisk">*</span></label>
              <textarea
                type="text"
                className='enquireFormTextarea'
                rows={3}
              />
            </div>
          </div>
          <div className="welEnquireBtn_cont">
            <button className="welcomeEnquireButton">Send</button>
          </div>
        </div>
      </div>

      {/* S E C T I O N 3 */}
      <div className="welcomeSection3">
        <div className="welcomeSection3Sub">
          <div className="welSecSubCont">
            <h1>{t("welSec3MainHead")}</h1>
            <h2>{t("welSec3SubHead")}</h2>
          </div>
        </div>
      </div>

      {/* S E C T I O N 4 */}
      <div className="welcomeSection4">
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Localises Campaigns:</p>
          </div>
          <div className="contentRow_cont2">
            <p>Deliver region-specific messages tailored to demographics for greater relevance and engagement.</p>
          </div>
        </div>
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Multi-Channel Outreach</p>
          </div>
          <div className="contentRow_cont2">
            <p>Utilize platforms like WhatsApp and social media to reach customers where they are most active.</p>
          </div>
        </div>
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Customer Segmentation:</p>
          </div>
          <div className="contentRow_cont2">
            <p>Identify and target your ideal audience based on their preferences, behavior, and purchase history</p>
          </div>
        </div>
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Rich Media Content:</p>
          </div>
          <div className="contentRow_cont2">
            <p>Leverage images, videos, and offers to craft compelling campaigns that drive action.</p>
          </div>
        </div>
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Promotions and Deals:</p>
          </div>
          <div className="contentRow_cont2">
            <p>Highlight exclusive offers and time-sensitive promotions to captivate your audience.</p>
          </div>
        </div>
      </div>

      {/* S E C T I O N 5 */}
      <div className="welcomeSection5">
        <div className="welcomeSection5Sub">
          <div className="welSecSubCont">
            <h1>{t("welSec5MainHead")}</h1>
            <h2>{t("welSec5SubHead")}</h2>
          </div>
        </div>
      </div>

      {/* S E C T I O N 6 */}
      <div className="welcomeSection6">
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Customized Business Solutions:</p>
          </div>
          <div className="contentRow_cont2">
            <p>Develop campaigns designed to showcase how your offerings address unique business challenges.</p>
          </div>
        </div>
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Lead Generation:</p>
          </div>
          <div className="contentRow_cont2">
            <p>Identify potential business clients and engage them with value-driven, targeted content.</p>
          </div>
        </div>
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Account-Based Marketing (ABM):</p>
          </div>
          <div className="contentRow_cont2">
            <p>Focus on high-value business accounts with personalized strategies to foster long-term partnerships.</p>
          </div>
        </div>
        <div className="welSecContentRow">
          <div className="contentRow_cont1">
            <p>Business Segmentation:</p>
          </div>
          <div className="contentRow_cont2">
            <p>Categorize your business audience by industry, size, and needs for precision targeting.</p>
          </div>
        </div>
      </div>

      {/* S E C T I O N 7 */}
      <div className="welcomeSection7">
        <div className="welcomeSection7Sub">
          <div className="welSecSubCont">
            <h1>{t("welSec7MainHead")}</h1>
            <h2>{t("welSec7SubHead")}</h2>
          </div>
        </div>
      </div>

      {/* S E C T I O N 8 */}
      <div className="welcomeSection8">
        <div className="contentCardCont">
          <p className='contentCardHead'>Unified Dashboard:</p>
          <p className='contentCardPara'>Centralize your marketplace data to gain clear insights into performance and opportunities.</p>
        </div>
        <div className="contentCardCont">
          <p className='contentCardHead'>Real-Time Price Monitoring:</p>
          <p className='contentCardPara'>Stay competitive with automated tracking and dynamic pricing suggestions.</p>
        </div>
        <div className="contentCardCont">
          <p className='contentCardHead'>Customer Insights:</p>
          <p className='contentCardPara'>Utilize data analytics to refine strategies and improve customer acquisition and retention.</p>
        </div>

      </div>

      {/* S E C T I O N 9 */}
      <div className="welcomeSection9">
        {/* Sub container 1 */}
        <div className="welSec9SubCont1">
          <div className="whyZumiMain_cont">
            <div className="whyZumiCont">
              <h2>Why choose Zumi?</h2>
            </div>
          </div>
          <div className="welSecContentRow">
            <div className="contentRow_cont1">
              <p>Wide Reach:</p>
            </div>
            <div className="contentRow_cont2">
              <p>Connect with diverse audiences across urban centers and regional Australia.</p>
            </div>
          </div>
          <div className="welSecContentRow">
            <div className="contentRow_cont1">
              <p>Scalable Solutions:</p>
            </div>
            <div className="contentRow_cont2">
              <p>Adaptable strategies designed to grow with your business.</p>
            </div>
          </div>
          <div className="welSecContentRow">
            <div className="contentRow_cont1">
              <p>Data-Driven Success:</p>
            </div>
            <div className="contentRow_cont2">
              <p>Optimize your campaigns with actionable insights for maximum impact.</p>
            </div>
          </div>
          <div className="welSecContentRow">
            <div className="contentRow_cont1">
              <p>Comprehensive Support:</p>
            </div>
            <div className="contentRow_cont2">
              <p>Partner with our team to develop and execute effective strategies aligned with your objectives.</p>
            </div>
          </div>
        </div>
        {/* Sub container 2 */}
        <div className="welSec9SubCont2">
          <p>With Zumi, you have the tools and expertise to amplify your brand presence and achieve measurable results in the competitive Australian market.</p>
        </div>
      </div>

      {/* S E C T I O N 10 */}
      <div className="welcomeSection10">
        <div className="whyWhatsappMain_cont">
          <div className="whyWhatsappCont">
            <h2>Why Choose Whatsapp Marketing </h2>
            <h3>for Business Growth?</h3>
          </div>
        </div>
        <div className="welSec10SubCont">
          <div className="welSec10WhatsappRow1">
            <div className="whatsappRow1Cont1">
              <h2>Direct Customer Engagement</h2>
              <p>WhatsApp enables businesses to connect with customers in a personal and direct manner. With high open rates and instant message delivery, it ensures your marketing messages are seen promptly.</p>
            </div>
            <div className="whatsappRow1Cont2">
              <img src={WhatsappImg1} alt="" />
            </div>
          </div>
          <div className="welSec10WhatsappRow2">
            <div className="whatsappRow2Cont1">
              <img src={WhatsappImg2} alt="" />
            </div>
            <div className="whatsappRow2Cont2">
              <h2>Cost-Effective Marketing</h2>
              <p>WhatsApp marketing eliminates the need for costly traditional advertising. It provides a budget-friendly alternative to engage customers through text, images videos, and more.</p>
            </div>
          </div>
          <div className="welSec10WhatsappRow1">
            <div className="whatsappRow1Cont1">
              <h2>Interactive Communication</h2>
              <p>Leverage features like polls, surveys, and real-time chats to create a two-way communication channel. This fosters stronger customer relationships and enhances user engagement.</p>
            </div>
            <div className="whatsappRow1Cont2">
              <img src={WhatsappImg3} alt="" />
            </div>
          </div>
          <div className="welSec10WhatsappRow2">
            <div className="whatsappRow2Cont1">
              <img src={WhatsappImg4} alt="" />
            </div>
            <div className="whatsappRow2Cont2">
              <h2>High Conversion Rates</h2>
              <p>With immediate and targeted communication, WhatsApp messages are more likely to be acted upon compared to emails or other marketing channels, leading to better ROI.</p>
            </div>
          </div>
          <div className="welSec10WhatsappRow1" style={{ height: "320px" }}>
            <div className="whatsappRow1Cont1">
              <h2>Secure and Trusted Platform</h2>
              <p>WhatsApp's end-to-encryption ensures secure communicatio, making it a trusted platform for customers to interact with your business.</p>
            </div>
            <div className="whatsappRow1Cont2">
              <img src={WhatsappImg5} alt="" />
            </div>
          </div>
        </div>

      </div>

      {/* S E C T I O N 11 */}
      {/* <div className="welcomeSection11">
        <div className="welSec11SubCont1">
          <h1>Pricing</h1>
          <p>You can establish your account now and choose a subscription plan at a later time. Experience all the premium features of ZUMI at no cost.</p>
        </div>

        <div className="welSec11SubCont2">
          <div className="welPriceCardCont">
            <div className="welPlanNameCont">
              <h2>Freemium</h2>
            </div>
            <div className="welPlanPriceCont">
              <h1>$ 49</h1>
              <h4>per month</h4>
            </div>
            <ul className='pricingFeatureList'>
              <li><CheckIcon style={{ color: "#06D001" }} />Amazon Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Ebay Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Shopify Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Profitability Dashboard</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Supplier Connect</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Supplier Quote Analytics</li>
              <li><CheckIcon style={{ color: "#06D001" }} />90 day event tracking</li>
              <li><CheckIcon style={{ color: "#06D001" }} />24*7 Phone support</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Customer Data in CRM</li>
              <li><CloseIcon style={{ color: "red" }} />Competitor tracking</li>
              <li><CloseIcon style={{ color: "red" }} />Dedicated Account Manager</li>
            </ul>
            <div className="welSelectPlanBtn">
              <button className="welcomeButton">Get Started</button>
            </div>
          </div>
          <div className="welPriceCardCont">
            <div className="welPlanNameCont">
              <h2>Starter</h2>
            </div>
            <div className="welPlanPriceCont">
              <h1>$ 99</h1>
              <h4>per month per marketplace</h4>
            </div>
            <ul className='pricingFeatureList'>
              <li><CheckIcon style={{ color: "#06D001" }} />Amazon Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Ebay Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Shopify Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Profitability Dashboard</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Supplier Connect</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Supplier Quote Analytics</li>
              <li><CheckIcon style={{ color: "#06D001" }} />90 day event tracking</li>
              <li><CheckIcon style={{ color: "#06D001" }} />24*7 Phone support</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Customer Data in CRM</li>
              <li><CloseIcon style={{ color: "red" }} />Competitor tracking</li>
              <li><CloseIcon style={{ color: "red" }} />Dedicated Account Manager</li>
            </ul>
            <div className="welSelectPlanBtn">
              <button className="welcomeButton">Get Started</button>
            </div>
          </div>
          <div className="welPriceCardCont">
            <div className="welPlanNameCont">
              <h2>Professional</h2>
            </div>
            <div className="welPlanPriceCont">
              <h1>$ 299</h1>
              <h4>per month per marketplace</h4>
            </div>
            <ul className='pricingFeatureList'>
              <li><CheckIcon style={{ color: "#06D001" }} />Amazon Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Ebay Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Shopify Integration</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Profitability Dashboard</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Supplier Connect</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Supplier Quote Analytics</li>
              <li><CheckIcon style={{ color: "#06D001" }} />365 day event tracking</li>
              <li><CheckIcon style={{ color: "#06D001" }} />24*7 Phone support</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Customer Data in CRM</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Competitor tracking</li>
              <li><CheckIcon style={{ color: "#06D001" }} />Dedicated Account Manager</li>
            </ul>
            <div className="welSelectPlanBtn">
              <button className="welcomeButton">Get Started</button>
            </div>
          </div>

        </div>

      </div> */}

      {/* S E C T I O N 12 */}
      <div className="welcomeSection12">
        <div className="welContactCont">
          <div className="welContactLabel">Contact</div>
          <div className="welContactFooter">
            <div className="welContactContent">
              <div className="welContactItem">
                <h3>Sales Queries:</h3>
                <p className="welContactDetail">sales@zumi.au</p>
              </div>
              <div className="welContactItem">
                <h3>Call:</h3>
                <p className="welContactDetail">1300 978 258</p>
              </div>
            </div>
            <div className="termsContFooter">
              <Link className='welContactDetail termsLink' to="/legal/privacy-policy">Privacy policy</Link> | <Link className='welContactDetail termsLink' to="/terms&conditions">Terms of service</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WelcomeMainContent;

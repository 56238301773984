import React, { useState, useEffect, useContext } from 'react';
import './whatsapp.css'
import DataTable from 'react-data-table-component';
// import NewTemplate from '../NewTemplate/NewTemplate'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import NoDataComponent from './NoData/NoData';
import ContactTableLoader from '../Loaders/ContactTableLoader/ContactTableLoader';
import Breadcrumbs from '../Toolbar/Breadcrumbs';

import CreateTemplate from './CreateTemplate/CreateTemplate';

import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CellTowerIcon from '@mui/icons-material/CellTower';
import HistoryIcon from '@mui/icons-material/History';
import LinkIcon from '@mui/icons-material/Link';
import QuizIcon from '@mui/icons-material/Quiz';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TemplateContext } from '../../Context/TemplateContext';
import { SegmentContext } from '../../Context/SegmentContext';
import { useNavigate } from 'react-router-dom';
import { UserDetailsContext } from '../../Context/UserDetailsContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1150,
  height: 580,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};


const connectAccountStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  height: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

// Declare FB globally to avoid the linter warning
/* global FB */

const Whatsapp = () => {

  const { t } = useTranslation();
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const authToken = localStorage.getItem('authToken');
  const userId = localStorage.getItem('userId');
  const { userDetails } = useContext(UserDetailsContext);
  const { templateArray, setTemplateArray } = useContext(TemplateContext);
  const { segmentArray, setSegmentArray } = useContext(SegmentContext);

  const [templateData, setTemplateData] = useState([]);
  const [segmentData, setSegmentData] = useState([]);
  const [templateLoader, setTemplateLoader] = useState(true);
  const [updateTemplates, setUpdateTemplates] = useState();

  const [deleteId, setDeleteId] = useState();
  const template_URL = `${baseUrl}/whatsapp/template?userID=${userId}`;
  const delete_URL = `${baseUrl}/whatsapp/delete`;
  const segment_URL = `${baseUrl}/zumi/api/getsegment`;
  const storeSession_URL = `${baseUrl}/whatsapp/store-session`;
  const storeSDK_URL = `${baseUrl}/whatsapp/store-sdk-response`;

  const [sortTemplateDirection, setSortTemplateDirection] = useState('desc');
  const [searchQuery, setSearchQuery] = useState('');

  const [updateId, setUpdateId] = useState(null);
  const [broadcastTemplateId, setBroadcastTemplateId] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [broadcastOpen, setBroadcastOpen] = React.useState(false);
  const handleBroadcastOpen = () => setBroadcastOpen(true);
  const handleBroadcastClose = () => setBroadcastOpen(false);

  const [connectStatusOpen, setConnectStatusOpen] = React.useState(false);
  const handleConnectStatusOpen = () => setConnectStatusOpen(true);
  const handleConnectStautsClose = () => setConnectStatusOpen(false);

  const [connectLoading, setConnectLoading] = useState(false);

  const [sessionInfo, setSessionInfo] = useState('');
  const [sdkResponse, setSdkResponse] = useState('');
  const [connectStatus, setConnectStatus] = useState(false);

  const metaRedirection_URL = "https://www.facebook.com/v22.0/dialog/oauth?display=popup&client_id=3435906306704131&redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Fes%2Foauth%2Fcallback%2F%3Fbusiness_id%3D301820897748593%26nonce%3DiNqSmgdEwlR1WmFWTbT6fbJoibz3PS6n&config_id=28235553029421364&response_type=code&auth_type&fallback_redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Fes%2Foauth%2Fcallback%2F%3Fbusiness_id%3D301820897748593%26nonce%3DiNqSmgdEwlR1WmFWTbT6fbJoibz3PS6n&override_default_response_type=true&extras=%7B%22sessionInfoVersion%22%3A%222%22%7D"
  const [activeTab, setActiveTab] = useState('broadcast');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const timeZoneMap = {
    "SAST": "Africa/Johannesburg",
    "AKST": "America/Anchorage",
    "ART": "America/Argentina/Buenos_Aires",
    "CST": "America/Chicago",
    "MST": "America/Denver",
    "EST": "America/Indianapolis",
    "PST": "America/Los_Angeles",
    "EST": "America/New_York",
    "MST": "America/Phoenix",
    "GST": "Asia/Dubai",
    "IST": "Asia/Kolkata",
    "PETT": "Asia/Kamchatka",
    "CST": "Asia/Shanghai",
    "JST": "Asia/Tokyo",
    "ACST": "Australia/Adelaide",
    "AEST": "Australia/Brisbane",
    "ACST": "Australia/Darwin",
    "AWST": "Australia/Perth",
    "AEST": "Australia/Sydney",
    "NST": "Canada/Newfoundland",
    "AST": "Canada/Atlantic",
    "EST": "Canada/Toronto",
    "CET": "Europe/Amsterdam",
    "CET": "Europe/Berlin",
    "GMT": "Europe/Guernsey",
    "GMT": "Europe/Isle_of_Man",
    "GMT": "Europe/London",
    "MSK": "Europe/Minsk",
    "MSK": "Europe/Moscow",
    "CET": "Europe/Paris",
    "GMT": "Europe/Jersey",
    "NZST": "Pacific/Auckland",
    "CHAST": "Pacific/Chatham",
    "HST": "Pacific/Honolulu"
  };

  const templateTableStyles = {
    headRow: {
      style: {
        position: "sticky",
        top: 0,
        color: "black",
        color: "rgb(14, 113, 195)",
        width: "100%",
        fontSize: "1rem",
        lineHeight: "24px",
      },
    },
    headCells: {
      style: {
        // border: "1px solid blue",
        padding: "10px",
        textAlign: "left",
      },
    },
    cells: {
      style: {
        // border: "1px solid red",
        padding: "10px",
        fontSize: "0.9rem",
        color: "black",
        lineHeight: "25px",
      },
    }
  }

  const columns = [
    {
      name: t('templateName'),
      selector: row => row.templateName,
    },
    {
      name: t('category'),
      selector: row => row.category.charAt(0).toUpperCase() + row.category.slice(1).toLowerCase(),
      // minWidth: "140px",
      width: "140px"
    },
    {
      name: t('status'),
      // selector: row => row.status,
      selector: row => row.status.charAt(0).toUpperCase() + row.status.slice(1).toLowerCase(),
      // minWidth: "140px",
      width: "140px"
    },
    {
      name: t('language'),
      // selector: row => row.language,
      selector: row => {
        if (row.language === "en") {
          return "English";
        }
        if (row.language === "en_US") {
          return "English (US)";
        }
        if (row.language === "en_UK") {
          return "English (UK)";
        }
      },
      // minWidth: "140px",
      width: "140px"
    },
    {
      name: t('createdDate'),
      selector: row => {
        const date = new Date(row.created_DateTime);

        const timeZone = timeZoneMap[userDetails.timezone] || userDetails.timezone;

        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        };

        const formattedDate = new Intl.DateTimeFormat('en-US', {
          ...options,
          timeZone: timeZone,
        }).format(date);

        return formattedDate;
      },
    },
    {
      name: t('actions'),
      selector: row => row.actions = <div className="actionsButtonsCont">
        <div className="userEditBtn centerElm">
          <EditIcon />
        </div>
        <div className="userDeleteBtn centerElm" onClick={() => deleteItem(row.messageId)}>
          <DeleteIcon />
        </div>
        {row.flag === 1 && (
          <div className="broadcastBtn_cont" onClick={() => openBroadcastTemplate(row.messageId)}>
            {t('broadcast')}
          </div>
        )}
        {/* {row.status === 'APPROVED' && (
          <div className="broadcastBtn_cont" onClick={() => openBroadcastTemplate(row.messageId)}>
            Broadcast
          </div>
        )} */}
        {/* <div className="broadcastBtn_cont" onClick={() => { openBroadcastTemplate(row.messageId) }}>
          {t('broadcast')}
        </div> */}
        {/* <div className="deleteBtn_cont" onClick={() => { deleteItem(row.messageId) }}>
          <DeleteIcon className='deleteIcon' style={{ height: '20px', width: '20px' }}></DeleteIcon>
        </div> */}
      </div>,
      style: {
        width: '120px'
      }
    },
  ];

  const [sortItem, setSortItem] = React.useState([
    { id: 1, value: "desc", name: t('latest') },
    { id: 2, value: "asc", name: t('oldest') },
  ]);

  // S E T   B R O A D C A S T   I D 
  function openBroadcastTemplate(id) {
    handleBroadcastOpen();
    setBroadcastTemplateId(id);
  }

  // D E L E T E  T E M P L A T E   I T E M
  const deleteItem = async (id) => {
    try {
      const response = await fetch(`${delete_URL}?id=${id}`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },

      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Update state after successful deletion
      setDeleteId(id);
      setTemplateData(templateData.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  // F E T C H   S E G M E N T S   D A T A 
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(segment_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setSegmentData(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, []);

  // S E T T I N G   S E G M E N T   C O N T E X T 
  useEffect(() => {
    if (segmentData) {
      setSegmentArray(segmentData);
    }
  }, [segmentArray, segmentData]);

  // F E T C H   T E M P L A T E   D A T A 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(template_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`, // Add the Authorization header
            'Content-Type': 'application/json' // Set content type if necessary
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setTemplateData(result);
      } catch (error) {
        console.log(error);
      } finally {
        setTemplateLoader(false);
      }
    };
    fetchData();
  }, [deleteId, updateTemplates]);

  // S E T T I N G   T E M P L A T E   C O N T E X T 
  useEffect(() => {
    if (templateData) {
      setTemplateArray(templateData);
    }
  }, [templateData, setTemplateArray, deleteId]);

  // S O R T   A N D   S E A R C H   T E M P L A T E   D A T A 
  const handleTemplateSort = (column, sortTemplateDirection) => {
    setSortTemplateDirection(sortTemplateDirection);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredAndSortedTemplateData = React.useMemo(() => {
    const filteredData = templateData.filter(item =>
      item.templateName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return filteredData.slice().sort((a, b) => {
      if (sortTemplateDirection === 'asc') {
        return new Date(a.created_DateTime) - new Date(b.created_DateTime);
      } else {
        return new Date(b.created_DateTime) - new Date(a.created_DateTime);
      }
    });
  }, [templateData, sortTemplateDirection, searchQuery]);

  const updateTemplateData = (value) => {
    setUpdateTemplates(value);
  }

  const redirectToMeta = () => {
    window.location.href = metaRedirection_URL;
  }



  // useEffect(() => {
  //   const loadFacebookSDK = () => {
  //     const script = document.createElement('script');
  //     script.src = 'https://connect.facebook.net/en_US/sdk.js';
  //     script.async = true;
  //     script.defer = true;
  //     script.crossOrigin = 'anonymous';
  //     script.onload = () => {
  //       window.fbAsyncInit = () => {
  //         FB.init({
  //           appId: '3435906306704131',
  //           autoLogAppEvents: true,
  //           xfbml: true,
  //           version: 'v22.0',
  //         });
  //       };
  //     };
  //     document.body.appendChild(script);
  //   };

  //   loadFacebookSDK();

  //   return () => {
  //     const script = document.querySelector('script[src="https://connect.facebook.net/en_US/sdk.js"]');
  //     if (script) script.remove();
  //   };
  // }, []);

  // // Handle the login callback
  // const fbLoginCallback = (response) => {
  //   if (response.authResponse) {
  //     const code = response.authResponse.code;
  //     console.log("Response: ", response);
  //     console.log("AuthResponse: ", response.authResponse);
  //     console.log('Logged in with code:', code);
  //     setConnectStatus(true);
  //     handleConnectStatusOpen();
  //   } else {
  //     console.error('Facebook login failed:', response);
  //     setConnectStatus(false)
  //     handleConnectStatusOpen();
  //   }
  // };


  // // Launch the WhatsApp Signup Flow
  // const launchWhatsAppSignup = () => {
  //   FB.login(fbLoginCallback, {
  //     config_id: '28235553029421364',
  //     response_type: 'code',
  //     override_default_response_type: true,
  //     extras: {
  //       setup: {},
  //       featureType: '',
  //       sessionInfoVersion: '2',
  //     },
  //   });
  // };

  // useEffect(() => {
  //   console.log("Connect Status : ", connectStatus)
  // }, [connectStatus]);





  // G E T    D A T A    F U N C T I O N
  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== "https://www.facebook.com" && event.origin !== "https://web.facebook.com") {
        return;
      }
      try {
        const data = JSON.parse(event.data);
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow
          if (data.event === 'FINISH') {
            const { phone_number_id, waba_id } = data.data;
            // console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
          } else if (data.event === 'CANCEL') {
            const { current_step } = data.data;
            console.warn("Cancel at ", current_step);
          } else if (data.event === 'ERROR') {
            const { error_message } = data.data;
            console.error("error ", error_message);
          }
        }
        setSessionInfo(JSON.stringify(data, null, 2));
      } catch {
        // console.log('Non JSON Responses', event.data);
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // F A C E B O O K    C A L L B A C K   F U N C T I O N 
  const fbLoginCallback = (response) => {
    if (response.authResponse) {
      const { code } = response.authResponse;
    }
    setSdkResponse(JSON.stringify(response, null, 2));
  };


  // L A U N C H   F U N C T I O N
  const launchWhatsAppSignup = () => {
    FB.login(fbLoginCallback, {
      config_id: '28235553029421364',
      response_type: 'code',
      override_default_response_type: true,
      extras: {
        setup: {},
        featureType: '',
        sessionInfoVersion: '2',
      }
    });
  };


  // L O A D I N G   S C R I P T 
  useEffect(() => {
    // Initialize the Facebook SDK
    window.fbAsyncInit = function () {
      FB.init({
        appId: '3435906306704131',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v22.0',
      });
    };

    // Load the SDK script
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.crossOrigin = 'anonymous';
    script.src = 'https://connect.facebook.net/en_US/sdk.js';
    document.body.appendChild(script);
  }, []);



  // C A L L I N G   S T O R E   S E S S I O N 
  useEffect(() => {
    if (sessionInfo) {
      const parsedSessionInfo = JSON.parse(sessionInfo);  // Parse the string to an object
      if (parsedSessionInfo && parsedSessionInfo.data) {
        storeSession();
      }
    }
  }, [sessionInfo]);

  // C A L L I N G   S T O R E   S D K  
  useEffect(() => {
    if (sdkResponse) {
      const parsedSDKResponse = JSON.parse(sdkResponse);  // Parse the string to an object
      storeSDK(parsedSDKResponse);
    }
  }, [sdkResponse]);


  // S T O R E    S E S S I O N   F U N C T I O N
  const storeSession = async () => {
    const parsedSessionInfo = JSON.parse(sessionInfo);  // Parse the string to an object
    if (parsedSessionInfo?.data?.phone_number_id && parsedSessionInfo?.data?.waba_id) {
      try {
        const requestData = {
          data: {
            phone_number_id: parsedSessionInfo.data.phone_number_id,
            waba_id: parsedSessionInfo.data.waba_id,
            userId: userId,
          },
          type: "WA_EMBEDDED_SIGNUP",
          event: "FINISH",
          version: 2,
        };
        const response = await fetch(storeSession_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          setConnectLoading(true);
          handleConnectStatusOpen();
        } else {
          setConnectStatus(false);
        }
      } catch (error) {
        console.error('Error posting session:', error);
      }
    } else {
      console.error('Session info is incomplete');
    }
  };

  // S T O R E   S D K    F U N C T I O N
  const storeSDK = async (parsedSDKResponse) => {
    if (parsedSDKResponse?.authResponse?.code) {
      try {
        const requestData2 = {
          authResponse: {
            userID: parsedSDKResponse.authResponse.userID,
            userId: userId,
            expiresIn: parsedSDKResponse.authResponse.expiresIn,
            code: parsedSDKResponse.authResponse.code,

          },
          status: parsedSDKResponse.status
        }
        const response = await fetch(storeSDK_URL, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(requestData2),
        });

        if (response.ok) {
          // handleConnectStatusOpen();
          setConnectLoading(false);
          setConnectStatus(true);
        } else {
          setConnectStatus(false);
        }
      } catch (error) {
        console.error('Error posting SDK:', error);
      }
    } else {
      console.log("Sdk response is not present.")
      // console.error('SDK response is incomplete');
    }
  };



  return (
    <div className='whatsapp_main'>

      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'broadcast' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp"
          >
            <CellTowerIcon style={{ marginRight: "8px" }} /> {t('broadcast')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'history' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/message-history"
          >
            <HistoryIcon style={{ marginRight: "8px" }} /> {t('messageHistory')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'connect' ? 'whatsapptabActive' : ''}`}
            // onClick={redirectToMeta}
            // onClick={handleLoginClick}
            onClick={launchWhatsAppSignup}
          >
            <LinkIcon style={{ marginRight: "8px" }} /> {t('connectAccount')}
          </Link>
          {/* <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'connect' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/connect-account"
          >
            <LinkIcon style={{ marginRight: "8px" }} /> {t('connectAccount')}
          </Link> */}
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'automation' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/automations"
          >
            <SettingsSuggestIcon style={{ marginRight: "8px" }} /> {t('automations')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'knowledge' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/whatsapp/knowledge"
          >
            <QuizIcon style={{ marginRight: "8px" }} /> {t('knowledgeBase')}
          </Link>
        </div>
      </div>

      <div className='searchAndbtn_cont'>
        <div className="searchTemplate_row">
          <div className="searchSortTemplate_cont">
            <TextField
              className='searchTemplateInput'
              id="outlined-size-small"
              size="small"
              placeholder='Search Templates'
              onChange={handleSearch}
            />
            <Select
              size="small"
              className='sortTemplateInput'
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={sortTemplateDirection}
              onChange={e => handleTemplateSort(null, e.target.value)}
            >
              {
                sortItem.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.value}>{item.name}</MenuItem>
                  )
                })
              }
            </Select>
          </div>

          <div className="templateRowBtn_cont">
            <button className='btnFill' onClick={handleOpen}>
              {t('newTemplate')}
            </button>

            {/* M O D A L   T O   C R E A T E   T E M P L A T E */}
            <Modal
              className='templateStyleMobile'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <CreateTemplate btnText="Submit" updateTemplateData={updateTemplateData} closeModal={handleClose} broadcastTemplateId={null} updateId={updateId} templateData={templateData}></CreateTemplate>
              </Box>
            </Modal>

            {/* M O D A L   F O R   B R O A D C A S T  */}
            <Modal
              className='templateStyleMobile'
              open={broadcastOpen}
              onClose={handleBroadcastClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <CreateTemplate btnText="Broadcast" updateTemplateData={updateTemplateData} closeModal={handleBroadcastClose} broadcastTemplateId={broadcastTemplateId} templateData={templateArray}></CreateTemplate>
              </Box>
            </Modal>

            {/* M O D A L   F O R   C O N N E C T   S U C C E S S   O R   F A I L   */}
            <Modal
              className='templateStyleMobile'
              open={connectStatusOpen}
              onClose={handleConnectStautsClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={connectAccountStyle}>

                <h2>Connect Status</h2>
                {connectLoading ? "Loading ..." : "Process complete"}
                {connectStatus ? "Connected Successfully" : "Failed to connect."}
              </Box>
            </Modal>
          </div>
        </div>
      </div>

      <div className='tableMain_cont'>
        <div className='table_cont'>
          {templateLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={templateTableStyles}
              noDataComponent={<NoDataComponent />}
              data={filteredAndSortedTemplateData}
              defaultSortField="created_DateTime"
              defaultSortAsc={sortTemplateDirection === 'asc'}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}
export default Whatsapp;
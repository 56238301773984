import React, { useEffect, useState, useContext } from 'react';
import './ViewBroadcast.css';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../Toolbar/Breadcrumbs';
import tableStyles from '../../Sales/Revenue/TableStyles';
import LocationSelect from '../../Dashboard/LocationSelect/LocationSelect';
import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import DataTable from 'react-data-table-component';
import { UserDetailsContext } from '../../../Context/UserDetailsContext';

const ViewBroadcast = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { userDetails } = useContext(UserDetailsContext);

  const authToken = localStorage.getItem('authToken');
  const broadcast_URL = `${baseUrl}/zumi/api/broadcast`;
  const [broadcastLoader, setBroadcastLoader] = useState(true);
  const [broadcastData, setBroadcastData] = useState([]);

  const [sortedBroadcastData, setSortedBroadcastData] = useState([]);


  const timeZoneMap = {
    "SAST": "Africa/Johannesburg",
    "AKST": "America/Anchorage",
    "ART": "America/Argentina/Buenos_Aires",
    "CST": "America/Chicago",
    "MST": "America/Denver",
    "EST": "America/Indianapolis",
    "PST": "America/Los_Angeles",
    "EST": "America/New_York",
    "MST": "America/Phoenix",
    "GST": "Asia/Dubai",
    "IST": "Asia/Kolkata",
    "PETT": "Asia/Kamchatka",
    "CST": "Asia/Shanghai",
    "JST": "Asia/Tokyo",
    "ACST": "Australia/Adelaide",
    "AEST": "Australia/Brisbane",
    "ACST": "Australia/Darwin",
    "AWST": "Australia/Perth",
    "AEST": "Australia/Sydney",
    "NST": "Canada/Newfoundland",
    "AST": "Canada/Atlantic",
    "EST": "Canada/Toronto",
    "CET": "Europe/Amsterdam",
    "CET": "Europe/Berlin",
    "GMT": "Europe/Guernsey",
    "GMT": "Europe/Isle_of_Man",
    "GMT": "Europe/London",
    "MSK": "Europe/Minsk",
    "MSK": "Europe/Moscow",
    "CET": "Europe/Paris",
    "GMT": "Europe/Jersey",
    "NZST": "Pacific/Auckland",
    "CHAST": "Pacific/Chatham",
    "HST": "Pacific/Honolulu"
  };


  const columns = [
    {
      name: t('srNo'),
      selector: (row, rowIndex) => rowIndex + 1,
      width: "80px",
    },
    {
      name: t('dealerName'),
      selector: row => row.supplierName,
      width: "170px",
    },
    {
      name: "Dealer Type",
      selector: row => row.dealerType,
    },
    {
      name: "Markup",
      selector: row => row.markup,
    },
    {
      name: t('createdDate'),
      selector: row => {
        const date = new Date(row.createddate);

        // Get the timezone from userDetails and map it to an IANA timezone
        const timeZone = timeZoneMap[userDetails.timezone] || userDetails.timezone;  // Use the mapped timezone or fallback to the original

        const options = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        };

        // Use the mapped timezone in Intl.DateTimeFormat
        const formattedDate = new Intl.DateTimeFormat('en-US', {
          ...options,
          timeZone: timeZone,
        }).format(date);

        return formattedDate;
      },
      width: "250px"
    },
  ];

  // G E T   B R O A D C A S T   D E T A I L S
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        setBroadcastLoader(true);
        try {
          const response = await fetch(broadcast_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setBroadcastData(result);
        } catch (error) {
          console.log(error);
        } finally {
          setBroadcastLoader(false);
        }

      };
      fetchData();
    }
  }, []);


  // S E T    S O R T E D   B R O A D C A S T    D A T A
  useEffect(() => {
    if (broadcastData.length > 0) {
      const sortedData = [...broadcastData].sort((a, b) => new Date(b.createddate) - new Date(a.createddate));
      setSortedBroadcastData(sortedData);
    }
  }, [broadcastData]);



  return (
    <div className='viewBroadcastMain_cont'>
      <Breadcrumbs />
      <div className="revenueTableMain_cont">
        <div className='revenueTable_cont'>
          {broadcastLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              data={sortedBroadcastData}
              customStyles={tableStyles}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewBroadcast;
import React, { useContext, useEffect } from 'react'
import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { UnitsSoldContext } from '../../../Context/UnitsSoldContext';

const UnitsChart = () => {

  const { unitsSoldArray } = useContext(UnitsSoldContext);

  const unitsSoldData = {
    data: {
      labels: unitsSoldArray.map((data) => data.value || "Units Sold"),
      datasets: [{
        // label: "Units Sold",
        data: unitsSoldArray.map((data) => data.count),
        borderColor: 'rgb(14, 113, 195)',
        borderWidth: 2.5,

        // backgroundColor: [
        //   'rgba(255, 26, 104, 0.2)',
        //   'rgba(54, 162, 235, 0.2)',
        //   'rgba(255, 206, 86, 0.2)',
        //   'rgba(75, 192, 192, 0.2)',
        //   'rgba(153, 102, 255, 0.2)',
        //   'rgba(255, 159, 64, 0.2)',
        //   'rgba(0, 0, 0, 0.2)',
        //   '#BFF6C3'
        // ]
      }]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            display: false,
            beginAtZero: true
          },
          ticks: {
            stepSize: 5,
            display: false
          },
          border: {
            display: false,
          }
        },
        x: {
          grid: {
            display: false,
          },
          ticks: {
            display: false,
          },
          border: {
            display: false,
          }
        }
      },
      plugins: {
        legend: {
          display: false,
        },
        // tooltip: {
        //   enabled: false // This hides the tooltips if you want to remove those as well
        // },
        // datalabels: {
        //   display: false // This hides data labels if you are using the datalabels plugin
        // }
      },
      elements: {
        line: {
          tension: 0.3
        }
      }
    }
  }
  return (
    <Line data={unitsSoldData.data} options={unitsSoldData.options} />
    // <Bar data={unitsSoldData.data} options={unitsSoldData.options} />
  )
}

export default UnitsChart;
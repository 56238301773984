import React from 'react';
import './Terms.css';
import ZumiBrandImg from '../../assets/zumiBrandImg.png'

const Terms = () => {
  const authToken = localStorage.getItem('authToken');
  return (
    <div className='termsMain_cont'>
      <div className="termsTool_cont">
        <div className="toolbarMain_cont">
          <div className="toolbarBrandMain_cont centerElm">
            <div className="toolBrandImg_cont">
              {/* <img src={ZumiBrandImg} alt="brandImg" /> */}
              <img src={ZumiBrandImg} alt="brandImg" style={{ height: 40, width: 80 }} />

              {/* <h1 className='toolbarBrandName'>ZUMI</h1> */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="termsInner_cont">
        <div className="termsSub_cont">
          <p className='termsMainHead'>Terms & Conditions</p>
          <div className="terms_cont">
            <p>This is an agreement between Zumi and any user of our websites including https://zumi.au/ whereas use may or may not result in a purchase, order fulfilment and shipment of items.</p>
            <p>Please ensure you have read these terms and conditions</p>
          </div>

          <div className="terms_cont">
            <p className='termsBoldPara'>Legal</p>
            <p>Zumi is not liable for any loss or damages incurred for any person(s) who illegally use this website or illegal use of our services. Any user of this website is responsible for ensuring they use the website within laws that apply to them.</p>
            <p className='termsBoldPara'>Liability</p>
            <p>Zumi has necessary methods and protection in place for our customers to use our website safely and securely. We are not liable for any damages or losses incurred which may be perceived to be connected to use with our website. Users of our website should take necessary precautions to ensure they are browsing all websites safely and do so entirely at their own risk.</p>
            <p className="termsBoldPara">Third Party Websites</p>
            <p>We are not responsible for any incorrect information posted on third party websites referring to our website including but not limited to information relating to pricing, policies, terms and conditions or any other information.</p>
            <p>We are not bound to information posted on other websites nor are we responsible for getting incorrect information removed from third party websites.</p>
            <p className="termsBoldPara">Information on our Website </p>
            <p>We reserve the right to update pricing, product listings at any time without notice.</p>
            <p className="termsBoldPara">Legal Trade Name</p>
            <p>Zumi is the legal trading entity of ZUMI DIGITAL PTY LTD ACN 652813673</p>
          </div>
        </div>
      </div> */}
      <div className="termsInner_cont">
        <div className="termsSub_cont">
          <p className='termsMainHead'>Terms & Conditions</p>
          <div className="terms_cont">
            <p>By using Zumi’s services, you agree to the terms outlined in this Customer Contract. These terms cover the use of Zumi’s services, which include syncing product catalogs, managing orders from various marketplaces (such as Amazon, eBay, and Shopify), as well as SMS and WhatsApp marketing. Please read these terms carefully. If you do not agree, do not sign an order form, create an account, or use our services.</p>
            <p>Our services are provided for legitimate business or commercial use only. You must comply with our Acceptable Use Policy at all times.</p>
          </div>

          <div className="terms_cont">
            <p className='termsBoldPara'>Customer Contract Structure</p>
            <p>Your Customer Contract consists of the following components, in the order of precedence:</p>
            <p className=''>1. <strong>Order Form: </strong>Specifies the agreed terms for services purchased, including pricing and service details.</p>
            <p className=''>2. <strong>Service Plan: </strong>Describes the features and services included in your selected package, including catalog syncing and marketing options.</p>
            <p className=''>3. <strong>Product Terms: </strong>Contains specific conditions relevant to the products and services you are utilizing (e.g., syncing and marketing services).</p>
            <p className=''>4. <strong>Additional Sections: </strong>.</p>
            <p className=''><strong>• Plan A: </strong>General Terms, applicable to all users.</p>
            <p className=''><strong>• Plan B: </strong>Reseller Terms, if you are reselling Zumi's services.</p>
            <p className=''><strong>• Plan C: </strong>Additional Security Terms, relevant for API users.</p>
            <p className=''><strong>• Plan D: </strong>Privacy Terms, applicable to customers.</p>

            <p className='termsBoldPara'>Resellers</p>
            <p>If you act as a reseller of Zumi’s services:</p>
            <p className=''>• You are responsible for ensuring that your clients comply with the terms of the Customer Contract.</p>
            <p className=''>• As a reseller, you are authorized to offer Zumi’s services (catalog syncing, SMS, and WhatsApp marketing) to your clients.</p>
            <p className=''>• Commission or revenue-sharing agreements are outlined in your Order Form, with initial customer support provided by you, and Zumi offering further assistance when necessary.</p>


            <p className='termsBoldPara'>Buyers</p>
            <p>For customers using Zumi’s services to purchase and manage products:</p>
            <p className=''><strong>• Order Management:: </strong>Easily view and manage orders across all connected platforms in one place.</p>
            <p className=''><strong>• Marketing Services: </strong>Leverage our SMS and WhatsApp tools to receive updates about your orders and special offers from sellers.</p>

            <p style={{ marginTop: "70px" }}>Effective Dates</p>
            <p>These terms are effective:</p>
            <p>Immediately, for customers whose contracts begin on or after 11 September 2024</p>
            <p>From 11 October 2024, for all existing customers.</p>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms;
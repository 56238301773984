import React, { useEffect, useState } from 'react';
import './TodaysDeals.css';

import { Link } from 'react-router-dom';

import BuyProduct from '../BuyProduct/BuyProduct';


import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { OutlinedInput } from '@mui/material';
import { Popover } from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import CallMadeIcon from '@mui/icons-material/CallMade';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

import DataTable from 'react-data-table-component';
import tableStyles from '../../Sales/Revenue/TableStyles';
import Slider from '@mui/material/Slider';
import { useTranslation } from 'react-i18next';

import ContactTableLoader from '../../Loaders/ContactTableLoader/ContactTableLoader';
import MakeOffer from '../MakeOffer/MakeOffer';

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      // borderColor: 'rgb(23, 193, 232)',
      borderRadius: "10px",
      // borderWidth: "1.5px",
    },
    '&:hover fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
    '&.Mui-focused fieldset': {
      // borderColor: 'black',
      borderRadius: "10px",
    },
  },
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const makeOfferStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  height: 350,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};


const TodaysDeals = () => {

  const { t } = useTranslation();

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem("authToken");
  const todaysDeals_URL = `${baseUrl}/zumi/api/broadcast-products`;

  const [activeTab, setActiveTab] = useState('todaysDeals');
  const handleTabClick = (tab) => setActiveTab(tab); // Set the active tab

  const [searchQuery, setSearchQuery] = useState('');
  const [makeOfferElm, setMakeOfferElm] = useState();

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);

  const [makeOfferOpen, setMakeOfferOpen] = useState(false);
  const handleMakeOfferOpen = (row) => {
    setMakeOfferElm(row);
    setMakeOfferOpen(true);
  }
  const handleMakeOfferClose = () => setMakeOfferOpen(false);

  const [priceRangeState, setPriceRangeState] = useState();

  const [todaysDealsLoader, setTodaysDealsLoader] = useState(true);

  const [alert, setAlert] = useState({ message: '', color: '', borderColor: '' });
  const [alertTimer, setAlertTimer] = useState(0);

  const [dealsFilter, setDealsFilter] = useState({
    priceRange: "priceRange",
    conditions: "all",
    sortBy: "sortByPrice"
  });


  const [todaysDeals, setTodaysDeals] = useState([]);

  const [value, setValue] = React.useState([0, 5000]);

  const columns = [
    {
      name: t('productInfo'),
      selector: row => row.brand,
      width: "350px"
    },
    // {
    //   name: t(''),
    //   selector: row => <div className="todaysDealsTableIcon_cont">
    //     {row.todaysDeals === "1" ? <Tooltip
    //       arrow
    //       title="Today's Deals"
    //       placement="top"
    //       slots={{
    //         transition: Zoom,
    //       }}
    //     >
    //       <WhatshotIcon />
    //     </Tooltip> : ""}

    //     {row.priceDrop === "1" ? <Tooltip
    //       arrow
    //       title="Price Drop"
    //       placement="top"
    //       slots={{
    //         transition: Zoom,
    //       }}
    //     >
    //       <TrendingDownIcon />
    //     </Tooltip> : ""}
    //     {row.priceUp === "1" ? <Tooltip
    //       arrow
    //       title="Price Up"
    //       placement="top"
    //       slots={{
    //         transition: Zoom,
    //       }}
    //     >
    //       <TrendingUpIcon />
    //     </Tooltip> : ""}

    //     {row.justLaunched === "1" ? <Tooltip
    //       arrow
    //       title="Just Launched"
    //       placement="top"
    //       slots={{
    //         transition: Zoom,
    //       }}
    //     >
    //       <RocketLaunchIcon />
    //     </Tooltip> : ""}
    //   </div>,
    //   width: "160px"
    // },
    {
      name: t('productPrice'),
      selector: row => row.price,
      width: "120px"
    },
    {
      name: t('quantity'),
      selector: row => row.quantity,
      width: "100px"
    },
    {
      name: t('resellerName'),
      selector: row => row.supplierName,
      width: "130px"
    },
    {
      name: t(''),
      selector: row => <div className="dashLiveBtn_cont">
        {/* <button className='btnFill dashLiveBtn' onClick={() => handleMakeOfferOpen(row)}>Make Offer</button> */}
        <button className='btnFill dashLiveBtn' onClick={handleOpen} style={{ marginLeft: "8px" }}>Buy Now</button>
      </div>,
    },
  ];

  // F U N C T I O N    T O    S H O W    A L E R T
  const showAlert = (message, color, borderColor) => {
    setAlert({ message, color, borderColor });
    setAlertTimer(3);

    // Start the countdown timer
    const intervalId = setInterval(() => {
      setAlertTimer((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId); // Clear interval when timer reaches 0
          setAlert({ message: '', color: '', borderColor: '' }); // Hide the alert
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Update every second
  };

  const handleChange = (e) => {
    setDealsFilter({ ...dealsFilter, [e.target.name]: e.target.value });
  }

  // F E T C H  T O D A Y S   D E A L S
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(todaysDeals_URL, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json'
          }
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();

        setTodaysDealsLoader(false);
        setTodaysDeals(result);
      } catch (error) {
        setTodaysDealsLoader(false)
        console.log(error);
      }
    };
    fetchData();
  }, []);

  // S O R T   A N D   S E A R C H   T O D A Y S   D E A L S   D A T A 

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleRangeChange = (event, newValue) => {
    setValue(newValue);
    // Update the price range in dealsFilter
    setDealsFilter({
      ...dealsFilter,
      priceRange: `${newValue[0]} - ${newValue[1]}`  // Set the price range in the format 'minPrice - maxPrice'
    });
  };

  useEffect(() => {
    setPriceRangeState(value[0] - value[1]);

    console.log("Price range: ", priceRangeState)
  }, [priceRangeState])

  const handleStockToggle = (event) => {
    setDealsFilter({ ...dealsFilter, conditions: event.target.checked ? "inStock" : "all" });
  };

  // const filteredSortedDeals = React.useMemo(() => {
  //   // Apply search filter
  //   let filteredData = todaysDeals.filter(item =>
  //     item.brand.toLowerCase().includes(searchQuery.toLowerCase())
  //   );

  //   // Apply price range filter
  //   if (dealsFilter.priceRange !== "priceRange") {
  //     const [minPrice, maxPrice] = dealsFilter.priceRange.split(" - ").map(Number);
  //     filteredData = filteredData.filter(item => {
  //       const price = parseFloat(item.price);
  //       return price >= minPrice && price <= maxPrice;
  //     });
  //   }

  //   // Apply in stock filter (if stock is greater than 0)
  //   if (dealsFilter.conditions === "inStock") {
  //     filteredData = filteredData.filter(item => item.quantity > 0);
  //   }

  //   // Apply sort filter
  //   if (dealsFilter.sortBy === "sortByPrice") {
  //     filteredData.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
  //   } else if (dealsFilter.sortBy === "sortByQuantity") {
  //     filteredData.sort((a, b) => a.quantity - b.quantity);
  //   }

  //   return filteredData;
  // }, [todaysDeals, searchQuery, dealsFilter]);

  const filteredSortedDeals = React.useMemo(() => {
    // Apply search filter
    let filteredData = todaysDeals.filter(item =>
      item.brand.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Apply price range filter if it's not "priceRange" (which means it's a valid range)
    if (dealsFilter.priceRange !== "priceRange" && dealsFilter.priceRange !== "") {
      const [minPrice, maxPrice] = dealsFilter.priceRange.split(" - ").map(Number);
      filteredData = filteredData.filter(item => {
        const price = parseFloat(item.price);
        return price >= minPrice && price <= maxPrice;
      });
    }

    // Apply in stock filter (if stock is greater than 0)
    if (dealsFilter.conditions === "inStock") {
      filteredData = filteredData.filter(item => item.quantity > 0);
    }

    // Apply sort filter
    if (dealsFilter.sortBy === "sortByPrice") {
      filteredData.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    } else if (dealsFilter.sortBy === "sortByQuantity") {
      filteredData.sort((a, b) => a.quantity - b.quantity);
    }

    return filteredData;
  }, [todaysDeals, searchQuery, dealsFilter]);


  const [openPriceRange, setOpenPriceRange] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Toggles the visibility of the div
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPriceRange((prev) => !prev);
  };

  const handleClosePriceRange = () => {
    setOpenPriceRange(false);
  };

  return (
    <div className='todaysDealsMain'>
      {/* Alert display */}
      {alert.message && (
        <div className="alert-container" style={{ backgroundColor: alert.color, border: `1px solid ${alert.borderColor}` }}>
          <div className="alert-message">
            {alert.message} <span>({alertTimer})</span>
          </div>
        </div>
      )}
      <div className="whatToolMain_cont">
        <div className="whatToolsBtns_cont">
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'home' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/buyer"
          >
            <HomeIcon style={{ marginRight: "8px" }} /> {t('home')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'todaysDeals' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/todays-deals"
          >
            <WhatshotIcon style={{ marginRight: "8px" }} /> {t('todaysDeals')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'priceDrop' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/price-drop"
          >
            <TrendingDownIcon style={{ marginRight: "8px" }} /> {t('priceDrop')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'newLaunched' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/new-launched"
          >
            <RocketLaunchIcon style={{ marginRight: "8px" }} /> {t('newLaunched')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'justSold' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/just-sold"
          >
            <CallMadeIcon style={{ marginRight: "8px" }} /> {t('justSold')}
          </Link>
          <Link
            // className='whatToolsLink'
            className={`whatToolsLink ${activeTab === 'liveOffers' ? 'whatsapptabActive' : ''}`}
            onClick={() => handleTabClick('material')}
            to="/home/live-offers"
          >
            <LocalOfferIcon style={{ marginRight: "8px" }} /> {t('liveOffers')}
          </Link>
        </div>
      </div>

      {/* M O D A L   T O   B U Y   P R O D U C T */}
      <Modal
        className='templateStyleMobile'
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <BuyProduct showAlert={showAlert} closeModal={handleClose}></BuyProduct>
        </Box>
      </Modal>

      {/* M O D A L   T O   M A K E    O F F E R */}
      <Modal
        className='templateStyleMobile'
        open={makeOfferOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={makeOfferStyle}>
          <MakeOffer showAlert={showAlert} makeOfferElm={makeOfferElm} closeModal={handleMakeOfferClose}></MakeOffer>
        </Box>
      </Modal>

      <div className="todaysDeals_cont1">
        <p>Today's Deals</p>
        <div className="todaysDealCont1Sub">

          <FormControl sx={{ m: 1, minWidth: "20%", borderRadius: "10px" }} size="small">
            <TextField
              sx={textFieldStyle}
              label="Search"
              id="outlined-size-small"
              size="small"
              onChange={handleSearch}
            />
          </FormControl>

          <FormControl sx={{ m: 1, minWidth: "18%", borderRadius: "10px" }} size="small">
            {/* <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              name="priceRange"
              value={dealsFilter.priceRange}
              onChange={handleChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 200, // Set your desired height
                    overflowY: 'auto',
                  },
                },
              }}
            >
              <MenuItem value="priceRange">Price Range</MenuItem>
              {priceRangeItems.map((item) => {
                return <MenuItem value={item.value}>{item.name}</MenuItem>
                })}
            </Select> */}

            <Select
              sx={{ borderRadius: "10px" }}
              id="demo-select-small"
              value={dealsFilter.priceRange}
              onClick={handleClick}
              // input={<OutlinedInput label="Select Option" />}
              open={false}
              renderValue={() => "Price Range"}
            >
              {/* Empty Select box, no items */}
              {/* <MenuItem value="priceRange">Price Range</MenuItem> */}
            </Select>
          </FormControl>

          <Popover
            sx={{ marginTop: "10px", borderRadius: "10px" }}
            open={openPriceRange}
            anchorEl={anchorEl}
            onClose={handleClosePriceRange}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            disableRestoreFocus
          >
            <Box
              sx={{
                p: 2,
                // border: '1px solid #ddd',
                backgroundColor: 'transparent',
                minWidth: 200,
              }}
            >
              {/* Your custom content here */}
              <div className='priceRangeMain_cont'>
                <h3>Price Range</h3>
                <Slider
                  sx={{ marginTop: "30px" }}
                  getAriaLabel={() => 'Price Range'}
                  value={value}
                  onChange={handleRangeChange}
                  valueLabelDisplay="auto"
                  valueLabelFormat={(value) => `$${value}`} // Format the value to display as currency
                  min={0}
                  max={10000}
                />

                <p style={{ color: "gray", marginTop: "10px" }}>Price Range: <span style={{ fontWeight: "700", color: "black" }}>${value[0]} - ${value[1]}</span></p>
              </div>
            </Box>
          </Popover>

          <FormControl sx={{ m: 1, minWidth: "18%", borderRadius: "10px" }} size="small">
            <Select
              sx={{ borderRadius: "10px" }}
              labelId="demo-select-small-label"
              id="demo-select-small"
              name="sortBy"
              value={dealsFilter.sortBy}
              onChange={handleChange}
            >
              <MenuItem value="sortByPrice">Sort by Price</MenuItem>
              <MenuItem value="sortByQuantity">Sort by Quantity</MenuItem>
            </Select>
          </FormControl>

          <div className="dealsInStockCont">
            <p>All</p>
            <FormControl>
              <Switch
                checked={dealsFilter.conditions === "inStock"}
                onChange={handleStockToggle}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </FormControl>
            <p>In Stock</p>
          </div>
        </div>
      </div>

      <h3 style={{ marginTop: "20px" }}>Today's top deals for you</h3>

      <div className='tableMain_cont'>
        <div className='table_cont'>

          {todaysDealsLoader ? (
            <ContactTableLoader />
          ) : (
            <DataTable
              columns={columns}
              customStyles={tableStyles}
              data={filteredSortedDeals}
              pagination
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default TodaysDeals;
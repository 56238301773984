import React, { useState, useContext, useEffect } from 'react';
import './Profile.css';
import UserProfile from '../../../src/assets/user-profile.jpg';
import { UserDetailsContext } from '../../Context/UserDetailsContext';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import EditProfile from './EditProfile/EditProfile';
import { useTranslation } from 'react-i18next';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  // height: 400,
  height: "auto",
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: "10px"
  // margin: 'auto',
  // width: '100%',
  // height: '100%',
  // border: '1px solid blue',
};

const Profile = () => {
  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const authToken = localStorage.getItem('authToken');
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);
  const userProfile_URL = `${baseUrl}/zumi/api/users/profile`;

  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => setOpen(true);
  const handleClose = () => setOpen(false);
  const [updateProfile, setUpdateProfile] = useState();

  const [alert, setAlert] = useState({ message: '', color: '', borderColor: '' });
  const [alertTimer, setAlertTimer] = useState(0);

  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(userProfile_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setUserDetails(result);
        } catch (error) {
          console.log(error);
        }

      };
      fetchData();
    }
  }, [updateProfile]);

  // F U N C T I O N    T O    S H O W    A L E R T
  const showAlert = (message, color, borderColor) => {
    setAlert({ message, color, borderColor });
    setAlertTimer(3);

    // Start the countdown timer
    const intervalId = setInterval(() => {
      setAlertTimer((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId); // Clear interval when timer reaches 0
          setAlert({ message: '', color: '', borderColor: '' }); // Hide the alert
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Update every second
  };

  const updateProfileData = (value) => {
    setUpdateProfile(value);
  }
  return (
    <div className='profileMain_cont'>
      {/* Alert display */}
      {alert.message && (
        <div className="alert-container" style={{ backgroundColor: alert.color, border: `1px solid ${alert.borderColor}` }}>
          <div className="alert-message">
            {alert.message} <span>({alertTimer})</span>
          </div>
        </div>
      )}
      <div className="profileSub_cont">
        <div className="profileHeader_cont">
          <div className="profilePhoto_cont">
            <div className="profilePhoto">
              <img className='userProfilePhoto' src={UserProfile} alt="" />
            </div>
          </div>
          <div className="profileName_cont">
            <div className="editProfileBtn_cont">
              <button className='btnNotFill' onClick={handleOpen}>Edit Profile</button>
            </div>
            {/* M O D A L   T O   E D I T    P R O F I L E */}
            <Modal
              className='templateStyleMobile'
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <EditProfile showAlert={showAlert} updateProfileData={updateProfileData} closeModal={handleClose}></EditProfile>
              </Box>
            </Modal>
            <p className='profileUserName'>{userDetails.firstName} {userDetails.lastName}</p>
            {/* <p className='profileUserDesc'>More Details about {userDetails.contactPerson}.</p> */}
          </div>
        </div>
        <hr className='profileHr' />
        <div className="profileData_cont">
          <div className="profileDataHeader_cont">
            <p>Personal Info</p>
          </div>
          <div className="profileDataSub_cont">
            <div className="profileData1">
              <p className='profileDataParaHeader'>Company</p>
              <p>{userDetails.companyName}</p>
            </div>
            <div className="profileData2">
              <p className='profileDataParaHeader'>Email</p>
              <p>{userDetails.emailId}</p>
            </div>
            <div className="profileData3">
              <p className='profileDataParaHeader'>Phone</p>
              <p>{userDetails.contactNumber}</p>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile;
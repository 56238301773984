import React, { useState, useEffect, useContext } from 'react';
import './MyAccount.css';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import SellIcon from '@mui/icons-material/Sell';
import ImageIcon from '@mui/icons-material/Image';

import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import { CurrencyContext } from '../../Context/CurrencyContext';
import { BrandLogoContext } from '../../Context/BrandLogoContext';
import { SubscriptionPlanContext } from '../../Context/SubscriptionPlanContext';
import { UserDetailsContext } from '../../Context/UserDetailsContext';

// const timezones = [
//   { label: 'Africa/Johannesburg (SAST) UTC +02:00', value: 'SAST' },
//   { label: 'America/Anchorage (AKST/AKDT) UTC -09:00', value: 'AKST' },
//   { label: 'America/Argentina/Buenos_Aires (ART) UTC -03:00', value: 'ART' },
//   { label: 'America/Chicago (CST/CDT) UTC -06:00', value: 'CST' },
//   { label: 'America/Denver (MST/MDT) UTC -07:00', value: 'MST' },
//   { label: 'America/Indianapolis (EST/EDT) UTC -05:00', value: 'EST' },
//   { label: 'America/Los_Angeles (PST/PDT) UTC -08:00', value: 'PST' },
//   { label: 'America/New_York (EST/EDT) UTC -05:00', value: 'EST' },
//   { label: 'America/Phoenix (MST) UTC -07:00', value: 'MST' },
//   { label: 'Asia/Dubai (GST) UTC +04:00', value: 'GST' },
//   { label: 'Asia/Kolkata (IST) UTC +05:30', value: 'IST' },
//   { label: 'Asia/Kamchatka (PETT) UTC +12:00', value: 'PETT' },
//   { label: 'Asia/Shanghai (CST) UTC +08:00', value: 'CST' },
//   { label: 'Asia/Tokyo (JST) UTC +09:00', value: 'JST' },
//   { label: 'Australia/Adelaide (ACST/ACDT) UTC +09:30', value: 'ACST' },
//   { label: 'Australia/Brisbane (AEST) UTC +10:00', value: 'AEST' },
//   { label: 'Australia/Darwin (ACST) UTC +09:30', value: 'ACST' },
//   { label: 'Australia/Perth (AWST) UTC +08:00', value: 'AWST' },
//   { label: 'Australia/Sydney (AEST/AEDT) UTC +10:00', value: 'AEST' },
//   { label: 'Canada/Newfoundland (NST/NDT) UTC -03:30', value: 'NST' },
//   { label: 'Canada/Atlantic (AST/ADT) UTC -04:00', value: 'AST' },
//   { label: 'Canada/Toronto (EST/EDT) UTC -05:00', value: 'EST' },
//   { label: 'Europe/Amsterdam (CET/CEST) UTC +01:00', value: 'CET' },
//   { label: 'Europe/Berlin (CET/CEST) UTC +01:00', value: 'CET' },
//   { label: 'Europe/Guernsey (GMT/BST) UTC +00:00', value: 'GMT' },
//   { label: 'Europe/Isle_of_Man (GMT/BST) UTC +00:00', value: 'GMT' },
//   { label: 'Europe/London (GMT/BST) UTC +00:00', value: 'GMT' },
//   { label: 'Europe/Minsk (MSK) UTC +03:00', value: 'MSK' },
//   { label: 'Europe/Moscow (MSK/MSD) UTC +03:00', value: 'MSK' },
//   { label: 'Europe/Paris (CET/CEST) UTC +01:00', value: 'CET' },
//   { label: 'Europe/Jersey (GMT/BST) UTC +00:00', value: 'GMT' },
//   { label: 'Pacific/Auckland (NZST/NZDT) UTC +12:00', value: 'NZST' },
//   { label: 'Pacific/Chatham (CHAST/CHADT) UTC +12:45', value: 'CHAST' },
//   { label: 'Pacific/Honolulu (HST) UTC -10:00', value: 'HST' }
// ];


const timezones = [
  { label: 'Pacific/Honolulu (HST) UTC -10:00', value: 'HST' },
  { label: 'America/Anchorage (AKST/AKDT) UTC -09:00', value: 'AKST' },
  { label: 'America/Los_Angeles (PST/PDT) UTC -08:00', value: 'PST' },
  { label: 'America/Denver (MST/MDT) UTC -07:00', value: 'MST' },
  { label: 'America/Phoenix (MST) UTC -07:00', value: 'MST' },
  { label: 'America/Chicago (CST/CDT) UTC -06:00', value: 'CST' },
  { label: 'America/Indianapolis (EST/EDT) UTC -05:00', value: 'EST' },
  { label: 'America/New_York (EST/EDT) UTC -05:00', value: 'EST' },
  { label: 'Canada/Atlantic (AST/ADT) UTC -04:00', value: 'AST' },
  { label: 'Canada/Newfoundland (NST/NDT) UTC -03:30', value: 'NST' },
  { label: 'America/Argentina/Buenos_Aires (ART) UTC -03:00', value: 'ART' },
  { label: 'Europe/Guernsey (GMT/BST) UTC +00:00', value: 'GMT' },
  { label: 'Europe/Isle_of_Man (GMT/BST) UTC +00:00', value: 'GMT' },
  { label: 'Europe/London (GMT/BST) UTC +00:00', value: 'GMT' },
  { label: 'Europe/Jersey (GMT/BST) UTC +00:00', value: 'GMT' },
  { label: 'Europe/Amsterdam (CET/CEST) UTC +01:00', value: 'CET' },
  { label: 'Europe/Berlin (CET/CEST) UTC +01:00', value: 'CET' },
  { label: 'Europe/Paris (CET/CEST) UTC +01:00', value: 'CET' },
  { label: 'Africa/Johannesburg (SAST) UTC +02:00', value: 'SAST' },
  { label: 'Europe/Minsk (MSK) UTC +03:00', value: 'MSK' },
  { label: 'Europe/Moscow (MSK/MSD) UTC +03:00', value: 'MSK' },
  { label: 'Asia/Dubai (GST) UTC +04:00', value: 'GST' },
  { label: 'Asia/Kolkata (IST) UTC +05:30', value: 'IST' },
  { label: 'Asia/Shanghai (CST) UTC +08:00', value: 'CST' },
  { label: 'Australia/Perth (AWST) UTC +08:00', value: 'AWST' },
  { label: 'Asia/Tokyo (JST) UTC +09:00', value: 'JST' },
  { label: 'Australia/Adelaide (ACST/ACDT) UTC +09:30', value: 'ACST' },
  { label: 'Australia/Darwin (ACST) UTC +09:30', value: 'ACST' },
  { label: 'Australia/Brisbane (AEST) UTC +10:00', value: 'AEST' },
  { label: 'Australia/Sydney (AEST/AEDT) UTC +10:00', value: 'AEST' },
  { label: 'Asia/Kamchatka (PETT) UTC +12:00', value: 'PETT' },
  { label: 'Pacific/Auckland (NZST/NZDT) UTC +12:00', value: 'NZST' },
  { label: 'Pacific/Chatham (CHAST/CHADT) UTC +12:45', value: 'CHAST' }
];



const currentTimezone = new Intl.DateTimeFormat().resolvedOptions().timeZone;
const defaultTimezoneAbbreviation = timezones.find((tz) => currentTimezone.includes(tz.value))?.value || 'IST';




const plansData = [
  // {
  //   "General": [
  //     { id: 1, featureName: "Users Included", growth: "5 Users", pro: "5 Users", business: "5 Users" },
  //     { id: 2, featureName: "Additional User Fee", growth: "₹699", pro: "₹1299", business: "₹3999" },
  //     { id: 3, featureName: "Discount on Zumi Conversation", growth: "N/A", pro: "N/A", business: "5%" }
  //   ]
  // },
  {
    "Whatsapp And Zumi Configuration": [
      { id: 1, featureName: "Official Whatsapp API", growth: "1", pro: "1", business: "1" },
      { id: 2, featureName: "Whatsapp Onboarding", growth: "1", pro: "1", business: "1" },
      { id: 3, featureName: "WhatsApp green tick verification assistance", growth: "1", pro: "1", business: "1" },
      { id: 4, featureName: "Website chat widget", growth: "0", pro: "1", business: "1" },
      { id: 5, featureName: "Meta Business Verification Assistance", growth: "1", pro: "1", business: "1" }
    ]
  },
  {
    "Contact Management": [
      { id: 1, featureName: "Import and Export Contacts", growth: "1", pro: "1", business: "1" },
      { id: 2, featureName: "Contact Attributes", growth: "1", pro: "1", business: "1" },
      { id: 3, featureName: "Click to WhatsApp ads (CTWA) analytics", growth: "0", pro: "1", business: "1" }
    ]
  },
  {
    "Marketing": [
      { id: 1, featureName: "Broadcasts", growth: "1", pro: "1", business: "1" },
      { id: 2, featureName: "Broadcast Scheduling (Onetime)", growth: "0", pro: "1", business: "1" },
      { id: 3, featureName: "Broadcast sequences", growth: "0", pro: "1", business: "1" }
    ]
  }
];


const MyAccount = () => {

  const { t } = useTranslation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { currency, setCurrency } = useContext(CurrencyContext);
  const { brandLogo } = useContext(BrandLogoContext);
  const { subscriptionPlan } = useContext(SubscriptionPlanContext);
  const { userDetails } = useContext(UserDetailsContext);


  const authToken = localStorage.getItem('authToken');
  const [activeTab, setActiveTab] = useState('month');
  const [langName, setLangName] = useState('en');
  const [timeZone, setTimeZone] = useState();

  const plans_URL = `${baseUrl}/zumi/api/plans`;
  const setting_URL = `${baseUrl}/zumi/api/users/updateaccount`;

  const [plans, setPlans] = useState([]);

  const [selectedLogo, setSelectedLogo] = useState("");
  const [logoSrc, setLogoSrc] = useState(null);

  const [logoTypeError, setLogoTypeError] = useState('');
  const [logoSizeError, setLogoSizeError] = useState('');

  const [eway_url, setEway_url] = useState("");
  const [loadingButton, setLoadingButton] = useState(null);

  const [alert, setAlert] = useState({ message: '', color: '', borderColor: '' });
  const [alertTimer, setAlertTimer] = useState(0);

  const [payment, setPaymentFrom] = useState({
    paymentFrom: "MyAccount",
    planDuration: "",
    selectedPlanId: ""
  })

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleLangChange = (e) => {
    setLangName(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const handleTimezoneChange = (e) => {
    setTimeZone(e.target.value);
  };

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  }

  const renderValue = (value) => {
    if (value === "1") {
      return <CheckIcon style={{ color: "blue" }} />;
    } else if (value === "0") {
      return <ClearIcon style={{ color: "red" }} />;
    } else {
      return value;
    }
  };

  // E W A Y   P A Y M E N T
  const handleEwayPayment = (amount, planName, planId) => {
    setLoadingButton(planName);
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${baseUrl}/zumi/api/payment/request-access-code?totalAmount=${amount}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          // Update the paymentFrom state with the new values
          if (activeTab) {
            setPaymentFrom(prevState => {
              const updatedState = { ...prevState, planDuration: activeTab };
              localStorage.setItem("paymentFrom", JSON.stringify(updatedState));  // Store the updated state
              return updatedState;
            });
          }

          if (planId) {
            setPaymentFrom(prevState => {
              const updatedState = { ...prevState, selectedPlanId: planId };
              localStorage.setItem("paymentFrom", JSON.stringify(updatedState));  // Store the updated state
              return updatedState;
            });
          }
          const result = await response.text();
          setEway_url(result);
          setTimeout(() => {
            window.location.href = result; // Redirect to the payment URL
          }, 1000); // 1-second delay (adjust as needed)

        }
        catch (error) {
          alert("Unable to generate access code. Please try again later.");
          console.log(error);
        }
        finally {
          setLoadingButton(null); // Reset loading button state
        }
      };
      fetchData();
    }
  }

  // R E D I R E C T    T O   E W A Y
  useEffect(() => {
    if (eway_url) {
      window.location.href = eway_url;
    }
  }, [eway_url]);

  useEffect(() => {
    if (userDetails) {
      setTimeZone(userDetails.timezone)
      setLangName(userDetails.language)
      setCurrency(userDetails.currency)
    }
  }, [userDetails])


  // L O G O   H A N D L E R 
  const handleLogoChange = (event) => {
    const image = event.target.files[0];

    if (image) {
      // Define allowed image types
      const validTypes = ['image/jpg', 'image/png'];

      // Define the maximum allowed size (in bytes)
      const maxSize = t("maxImageHeaderSize") * 1024 * 1024; // 30 MB

      if (validTypes.includes(image.type)) {
        if (image.size <= maxSize) {
          setSelectedLogo(image);
          setLogoTypeError('');

          const reader = new FileReader();

          reader.onloadend = () => {
            setLogoSrc(reader.result);
          };

          reader.readAsDataURL(image);
          setLogoTypeError('');
          setLogoSizeError('');
        } else {
          setLogoSrc(null);
          setSelectedLogo(null);
          setLogoSizeError(t("imageSizeError"));
        }
      } else {
        setLogoSrc(null);
        setSelectedLogo(null);
        setLogoTypeError(t("imageError"));
      }
    } else {
      setSelectedLogo(null);
      setLogoSrc(null);
      setLogoTypeError("");
      setLogoSizeError("");
    }
  };

  // C H A N G E   L O G O
  useEffect(() => {
    if (selectedLogo) {
      changeLogo();
    }
  }, [selectedLogo]);

  const changeLogo = () => {
    const formData = new FormData();
    formData.append('file', selectedLogo);

    for (let entry of formData.entries()) {
      console.log(entry[0] + ': ' + entry[1]);
    }

    try {
      const response = fetch(`${baseUrl}/zumi/api/upload`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
        },
        body: formData,
      });
      if (response.ok) {
        console.log('Logo changed successfully');
      } else {
        console.error('Failed to change logo.');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

  // G E T   P L A N S
  useEffect(() => {
    if (authToken) {
      const fetchData = async () => {
        try {
          const response = await fetch(plans_URL, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${authToken}`, // Add the Authorization header
              'Content-Type': 'application/json' // Set content type if necessary
            }
          });
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setPlans(result);
        } catch (error) {
          console.log(error);
        }
      };
      fetchData();
    }
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const requestData = {
      timezone: timeZone,
      language: langName,
      currency: currency,
    };

    console.log("Request data : ", requestData);

    try {
      const response = await fetch(setting_URL, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        showAlert("Failed to update settings.", '#FFE4C0', '#FD5D5D');
      }
      else {
        const data = await response.json();
        showAlert("Settings updated successfully.", '#d4f4e6', '#77d98b');
      }

    } catch (err) {
      console.error('Error posting data:', err);
      showAlert("Settings not updated due to an error.", '#FFE4C0', '#FD5D5D');
    } finally {
    }

  };

  // F U N C T I O N    T O    S H O W    A L E R T
  const showAlert = (message, color, borderColor) => {
    setAlert({ message, color, borderColor });
    setAlertTimer(3);

    // Start the countdown timer
    const intervalId = setInterval(() => {
      setAlertTimer((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId); // Clear interval when timer reaches 0
          setAlert({ message: '', color: '', borderColor: '' }); // Hide the alert
          return 0;
        }
        return prev - 1;
      });
    }, 1000); // Update every second
  };



  return (
    <div className='myAccountMain_cont'>
      {/* Alert display */}
      {alert.message && (
        <div className="alert-container" style={{ backgroundColor: alert.color, border: `1px solid ${alert.borderColor}` }}>
          <div className="alert-message">
            {alert.message} <span>({alertTimer})</span>
          </div>
        </div>
      )}
      <div className="myAccountSub_cont1">
        <div className="companyLogoMain_cont">
          <h3 className='' style={{ textAlign: "center" }}>{t("companyLogo")}</h3>

          <div className="companyLogo_cont" style={{ textAlign: "center" }}>
            {logoSrc ? (
              <img src={logoSrc} alt="Selected Logo" />
            ) : (
              brandLogo && <img src={brandLogo} alt="brandImg" />
            )}
          </div>
          {
            subscriptionPlan === 3 && (
              <div className="changeLogoBtn_cont">
                <input
                  type='file'
                  accept=".png"
                  className='uploadFileBtn smsInputField'
                  name="smsContactFile"
                  id="uploadBtn"
                  onChange={handleLogoChange}
                />
                <label
                  htmlFor="uploadBtn"
                  className='changeLogoBtn'
                >
                  <ImageIcon /> {t("changeLogo")}
                </label>
              </div>
            )
          }
          {
            subscriptionPlan === 3 && (
              <div className="logoErrors_cont">
                {logoSrc && <p style={{ color: "green", fontSize: "13px" }}>{selectedLogo.name}</p>}
                {logoTypeError && <p style={{ color: "red", fontSize: "13px" }}>{logoTypeError}</p>}
                {logoSizeError && <p style={{ color: "red", fontSize: "13px" }}>{logoSizeError}</p>}
              </div>
            )
          }
        </div>
        <div className="myAccountFormCont">
          <div className="myAccInput_cont" style={{ marginRight: "10px" }}>
            <label htmlFor="contactPerson" className="welcomeFormLabel">{t("customerTimezone")}  <span className="asterisk">*</span></label>
            <Select
              className="myAccInput_cont"
              value={timeZone || defaultTimezoneAbbreviation}
              onChange={handleTimezoneChange}
              labelId="timezone-select-label"
              id="timezone-select"
              size="small"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 400,
                    overflowY: 'auto',
                    maxWidth: 500
                  }
                }
              }}
            >
              {timezones.map((tz) => (
                <MenuItem key={tz.value} value={tz.value}>
                  {tz.label}
                </MenuItem>
              ))}
            </Select>



          </div>
          <div className="myAccountFormRow">
            <div className="myAccInput_cont" style={{ marginRight: "10px" }}>
              <label htmlFor="language" className="welcomeFormLabel">{t("language")}  <span className="asterisk">*</span></label>
              <Select
                className='myAccInput_cont'
                size='small'

                labelId="demo-select-small-label"
                id="demo-select-small"
                name='locales'
                value={langName}
                onChange={handleLangChange}
                displayEmpty
                renderValue={(selected) => {
                  if (selected === '') {
                    return "English";
                  }
                  if (selected === 'ch') {
                    return "Chinese";
                  }
                  if (selected === 'en') {
                    return "English";
                  }
                  if (selected === "fr") {
                    return "French";
                  }
                  if (selected === "hi") {
                    return "Hindi";
                  }
                  return selected;
                }}
              >
                <MenuItem value="ch">Chinese</MenuItem>
                <MenuItem value="en">English</MenuItem>
                <MenuItem value="fr">French</MenuItem>
                <MenuItem value="hi">Hindi</MenuItem>
              </Select>
            </div>
            <div className="myAccInput_cont">
              <label htmlFor="currency" className="welcomeFormLabel">{t("currency")}  <span className="asterisk">*</span></label>
              <Select
                className='myAccInput_cont'
                size='small'

                labelId="demo-select-small-label"
                id="demo-select-small"
                name='currency'
                value={currency}
                onChange={handleCurrencyChange}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 230, // Set your desired height
                      overflowY: 'auto',
                    },
                  },
                }}
              >
                <MenuItem value="AUD">AUD</MenuItem>
                <MenuItem value="CAD">CAD</MenuItem>
                <MenuItem value="CNY">CNY</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
                <MenuItem value="HKD">HKD</MenuItem>
                <MenuItem value="INR">INR</MenuItem>
                <MenuItem value="JPY">JPY</MenuItem>
                <MenuItem value="NZD">NZD</MenuItem>
                <MenuItem value="USD">USD</MenuItem>
              </Select>
            </div>
          </div>
          <div className="myAccFormBtn_cont">
            <button className='btnNotFill' onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      </div>

      {/* P R I C I N G    H E A D E R */}
      <div className="accPriceHeaderMain_cont">
        <div className="accPriceHeaderCol1">
          <p>{t("filterPara")}</p>
          <div className="accFilterCont">
            <div
              className={`monthTab centerElm ${activeTab === 'month' ? 'monthTabActive' : ''}`}
              onClick={() => handleTabClick('month')}>
              {t("month")}
            </div>
            <div
              className={`yearTab centerElm ${activeTab === 'year' ? 'monthTabActive' : ''}`}
              onClick={() => handleTabClick('year')}
            >
              {t("annual")}
            </div>
          </div>
          <div className="plansOfferCont centerElm">
            <SellIcon style={{ height: "50%" }} />{t("discountPara")}
          </div>
        </div>

        {plans.map((item) => (
          <div key={item.plan_id} className="accPriceHeaderCol">
            <div className="accPlanDetailsCont">
              <h2>{item.plan_name}</h2>
              <p>{item.description}</p>
            </div>
            <div className="accPlanCardBtn_cont">
              {activeTab === "month" && (
                <p className='accountPricePara'>
                  <span>$ {item.price_month} {item.currency}</span> / {activeTab}
                </p>
              )}

              {activeTab === "year" && (
                <p className='accountYearPricePara'>
                  <span style={{ textDecoration: 'line-through' }}>$ {item.price_year} {item.currency}</span> / {activeTab}
                </p>
              )}
              {activeTab === "year" && (
                <p className='accountPricePara'>
                  <span>$ {item.price_year} {item.currency}</span> / {activeTab}
                </p>
              )}

              {
                activeTab === "month" && (
                  <button
                    className='btnFill choosePlanBtn'
                    onClick={() => handleEwayPayment(item.price_month, item.plan_name, item.plan_id)}
                    disabled={loadingButton}
                    style={{
                      border: loadingButton ? "1px solid #EEEEEE" : "#17c1e8",
                      backgroundColor: loadingButton ? '#EEEEEE' : '#17c1e8',
                      color: loadingButton ? 'gray' : '#fff',
                    }}
                  >
                    {loadingButton === item.plan_name ? <div className="loader"></div> : "Choose Plan"}
                  </button>
                )
              }
              {
                activeTab === "year" && (
                  <button
                    className='btnFill choosePlanBtn'
                    onClick={() => handleEwayPayment(item.price_year, item.plan_name, item.plan_id)}
                    disabled={loadingButton}
                    style={{
                      border: loadingButton ? "1px solid #EEEEEE" : "#17c1e8",
                      backgroundColor: loadingButton ? '#EEEEEE' : '#17c1e8',
                      color: loadingButton ? 'gray' : '#fff',
                    }}
                  >
                    {loadingButton === item.plan_name ? <div className="loader"></div> : "Choose Plan"}
                  </button>
                )
              }
            </div>
          </div>
        ))}

      </div>

      {/* P R I C I N G   T A B L E */}
      <div className="pricingTableCont">
        {plansData.map((category, index) => {
          const categoryName = Object.keys(category)[0];
          const features = category[categoryName];
          return (
            <div key={index} className='pricingCatCont'>
              <div className="accPriceCatHeader">
                <div className="accPriceCatName">
                  <p>{categoryName}</p>
                </div>
                <div className="accOption"></div>
                <div className="accOption"></div>
                <div className="accOption"></div>
              </div>

              {features.map((feature => (
                <div className="priceRow">
                  <div className="featureNameCont">
                    {renderValue(feature.featureName)}
                  </div>
                  <div className="accOption centerElm">
                    {renderValue(feature.growth)}
                  </div>
                  <div className="accOption centerElm">
                    {renderValue(feature.pro)}
                  </div>
                  <div className="accOption centerElm">
                    {/* {feature.business} */}
                    {renderValue(feature.business)}
                  </div>
                </div>
              )))}
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default MyAccount;